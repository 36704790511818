import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { COLORS } from '../../../constants';
import { GroupedFiles } from 'pages/Calendar';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import { formatDuration } from 'utils/dateUtils';
import { parseSeizureEnum } from 'utils/seizureEnum';

interface DaySummaryProps {
  dayDetails: GroupedFiles;
  defaultOpen: boolean;
}

export const DaySummary: FC<DaySummaryProps> = ({ dayDetails, defaultOpen }) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  const { t } = useTranslation();

  const defaultReviews: Record<REVIEW_RESULT_STATUS, number> = {
    DETECTED: 0,
    NOT_DETECTED: 0,
    SKIPPED: 0,
    UNCERTAIN: 0,
  };

  const reviews = dayDetails.files.reduce((acc, file) => {
    file.markers.forEach((marker) => {
      if (marker.doctorReview) {
        acc[marker.doctorReview.reviewResult]++;
      }
    });
    return acc;
  }, defaultReviews);

  const totalReviews = Object.values(reviews).reduce((acc, value) => acc + value, 0);

  const getMarkerDescription = (marker: FileMarker) => {
    const { doctorReview } = marker;

    if (!doctorReview) return '-';

    const status = t(`reviewResult.${doctorReview.reviewResult}`);
    const parsedSeizureEnum = doctorReview?.seizureType
      ? parseSeizureEnum(doctorReview.seizureType)
      : null;
    const seizureTypeText = parsedSeizureEnum
      ? parsedSeizureEnum.map((seizureType) => t(`reviewSeizure.${seizureType}`)).join(', ')
      : null;

    const parts = [status, seizureTypeText, doctorReview.description].filter(Boolean);
    return parts.join(', ');
  };

  return (
    <Accordion
      expanded={expanded}
      disableGutters
      onChange={() => setExpanded(!expanded)}
      sx={{
        bgcolor: 'transparent',
        boxShadow: 'none',
        borderBottom: `1px solid #67798A70`,
        borderRadius: '0 !important',
      }}
    >
      <AccordionSummary expandIcon={<ArrowDownIcon />} sx={{ px: 0 }}>
        <Typography variant="h4" mr="70px">
          {dayDetails.date}
        </Typography>
        <Stack direction="row" gap="20px" alignSelf="center">
          {Object.entries(reviews).map(([key, value]) => (
            <Typography variant="P3_SB" key={key}>
              {t(`reviewResult.${key}`)}:{' '}
              <Typography variant="P4_R" ml="2px">{`${value}/${totalReviews}`}</Typography>
            </Typography>
          ))}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: '4px 0 16px 0' }}>
        <Stack>
          {dayDetails.files.map((file, videoIndex) =>
            file.markers.map((marker, markerIndex) => (
              <Stack
                key={marker.id}
                p="8px 12px"
                gap="4px"
                bgcolor={videoIndex % 2 ? 'inherit' : COLORS.WHITE}
              >
                <Stack direction="row" gap="24px">
                  <Typography variant="P5_R">{`#${videoIndex + 1} Video`}</Typography>
                  <Typography variant="P5_R">{`#${markerIndex + 1} Mark`}</Typography>
                  <Typography variant="P5_R">{`Time: ${formatDuration(
                    (marker.endSecond || file.lengthInSeconds) - marker.startSecond
                  )}`}</Typography>
                </Stack>
                <Typography variant="P4_R">{getMarkerDescription(marker)}</Typography>
              </Stack>
            ))
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
