import { Alert, Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { BACKGROUND, COLORS, DEFAULT_URL } from '../constants';
import { LogoBlack } from '../assets/LogoBlack';
import { useSignIn } from 'api/queries/auth/useSignIn';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePasswordReset } from '../api/queries/auth/usePasswordReset';
import { useGetConfig } from '../api/queries/auth/useGetConfig';

type OnSubmitProps = {
  username?: string;
  password?: string;
};

export const Login = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isLoading, isSubmitting },
  } = useForm();

  const { data: config } = useGetConfig();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { link } = usePasswordReset({ keycloakUrl: config?.keycloakUrl });
  const isLoginError = errors.password || errors.username || errors.customError || false;

  const onSubmit = async ({ username = '', password = '' }: OnSubmitProps) => {
    try {
      clearErrors();
      await signIn.mutateAsync({
        username,
        password,
      });
      navigate(DEFAULT_URL.DASHBOARD);
    } catch (err) {
      setError('customError', { type: 'custom', message: 'Invalid login' });
    }
  };

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        background: BACKGROUND.LOGIN,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          minWidth: '100%',
          minHeight: '100vh',
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate width={336}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LogoBlack />
          </Box>
          <Box sx={{ mt: isLoginError ? 3 : 10 }}>
            {isLoginError && (
              <Alert
                severity="error"
                icon={false}
                sx={{
                  mb: 2,
                  background: COLORS.BLACK_PRIMARY,
                  color: '#fff',
                  borderRadius: '8px',
                }}
              >
                {t('login.validation.error')}
              </Alert>
            )}
            <Typography
              sx={{
                color: COLORS.BLACK_SECOND,
                fontSize: '0.875rem',
              }}
            >
              {t('login.typeUsername')}
            </Typography>
            <TextField
              sx={{ mt: 0.6 }}
              margin="normal"
              required
              fullWidth
              id="username"
              placeholder={t('login.typeUsername')}
              autoComplete="username"
              autoFocus
              error={!!isLoginError}
              {...register('username', { required: true, onChange: () => clearErrors() })}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                color: COLORS.BLACK_SECOND,
                fontSize: '0.875rem',
              }}
            >
              {t('login.password')}
            </Typography>
            <TextField
              sx={{ mt: 0.6 }}
              margin="normal"
              required
              fullWidth
              placeholder={t('login.password')}
              type="password"
              id="password"
              autoComplete="password"
              error={!!isLoginError}
              {...register('password', { required: true, onChange: () => clearErrors() })}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Link href={link} variant="body2" sx={{ color: COLORS.GREEN_SECOND }}>
              {t('login.forgotPassword')}
            </Link>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            sx={{
              mt: 5,
              mb: 2,
            }}
            size="large"
            disabled={isLoading || isSubmitting}
          >
            {t('login.signIn')}
          </Button>
          <Typography textAlign={'center'} variant="body2">
            <Trans
              i18nKey={'common.orRegister'}
              components={{
                Link: <Link href={'/register'} sx={{ color: COLORS.GREEN_SECOND }} />,
              }}
            />
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
