import { Slider } from '@mui/material';
import { SyntheticEvent } from 'react';

type VideoPlayerTimelineProps = {
  color: 'primary' | 'secondary';
  played: number;
  seekHandler: (e: SyntheticEvent | Event) => void;
  seekMouseUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
  onSeekMouseDownHandler: (e: SyntheticEvent | Event) => void;
};

export const VideoPlayerTimeline = ({
  color,
  played,
  seekHandler,
  seekMouseUpHandler,
  onSeekMouseDownHandler,
}: VideoPlayerTimelineProps) => {
  return (
    <Slider
      color={color}
      min={0}
      max={100}
      value={played * 100}
      onChange={seekHandler}
      onChangeCommitted={seekMouseUpHandler}
      onMouseDown={onSeekMouseDownHandler}
    />
  );
};
