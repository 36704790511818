import { useModal } from 'hooks/useModal';
import { ModalContent } from '../../GlobalModal';
import { Alert, Box, Button, CircularProgress, OutlinedInput, Typography } from '@mui/material';
import { COLORS } from '../../../../constants';
import { CloseModalIcon } from '../../CloseModalIcon';
import { useForm } from 'react-hook-form';
import { useGetInvitationCode } from '../../../../api/queries/patient/useGetInvitationCode';
import { useInvitationCodeSend } from '../../../../api/queries/patient/useInvitationCodeSend';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { isValidEmail } from '../../../../utils';
import toast from 'react-hot-toast';

type OnSubmitProps = {
  email?: string;
};

export const ParentInvitationModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { data, isLoading: isInvitationCodeLoading, refetch } = useGetInvitationCode();
  const invitationCodeSend = useInvitationCodeSend();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isLoading, isSubmitting },
  } = useForm();

  const onSubmit = async ({ email }: OnSubmitProps) => {
    try {
      if (!data?.code || !email) return;
      await invitationCodeSend.mutateAsync({
        email,
        code: data.code,
      });
      closeModal();
      toast.success(t('modal.invitationCodeModal.successMessage'));
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errCode = err?.response?.data?.code;
        setError('customError', {
          type: 'custom',
          message: errCode ? t(`error.${errCode}`) : err.message,
        });
      }
    }
  };

  const handleEmailValidation = (email: string) => isValidEmail(email);

  const isApiError = errors.customError;

  return (
    <>
      <ModalContent component="form" onSubmit={handleSubmit(onSubmit)}>
        <CloseModalIcon />
        <Typography sx={{ mt: 2, color: COLORS.WHITE, fontSize: '1.25rem', fontWeight: '500' }}>
          {t('modal.invitationCodeModal.title')}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: COLORS.WHITE }}>
            {isInvitationCodeLoading ? <CircularProgress /> : data?.code}
          </Typography>
          <Button
            onClick={() => refetch()}
            disabled={isInvitationCodeLoading}
            variant="outlined"
            color="additional"
            size="small"
            sx={{ minWidth: '210px' }}
          >
            {t('modal.invitationCodeModal.generateNewCode')}
          </Button>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Alert severity="error" icon={false}>
            {t('modal.invitationCodeModal.warning')}
          </Alert>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          <Typography variant="label" sx={{ color: COLORS.WHITE }}>
            {t('modal.invitationCodeModal.input.label')}
          </Typography>
          <OutlinedInput
            {...register('email', {
              required: true,
              onChange: () => clearErrors(),
              validate: handleEmailValidation,
            })}
            required
            id="email"
            color="secondary"
            placeholder={t('modal.invitationCodeModal.input.placeholder')}
            size="small"
            value={getValues('email')}
          />
        </Box>
        <Box sx={{ mt: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" size="small" onClick={closeModal} sx={{ minWidth: '210px' }}>
            {t('modal.invitationCodeModal.cancel')}
          </Button>
          <Button
            disabled={
              isLoading ||
              isSubmitting ||
              !data?.code ||
              !getValues('email') ||
              !handleEmailValidation(getValues('email'))
            }
            type="submit"
            variant="contained"
            size="small"
            sx={{ minWidth: '210px' }}
          >
            {t('modal.invitationCodeModal.submit')}
          </Button>
        </Box>
        {isApiError && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {errors?.customError?.message as string}
          </Alert>
        )}
      </ModalContent>
    </>
  );
};
