import { COLORS } from '../../constants';
import { REVIEW_STATUS } from '../../contexts/PatientListProvider';

export const STATUS_VARIANT_DETAILS = {
  [REVIEW_STATUS.AWAITING_REVIEW]: {
    label: REVIEW_STATUS.AWAITING_REVIEW,
    color: COLORS.PRIMARY,
  },
  [REVIEW_STATUS.REVIEWED]: {
    label: REVIEW_STATUS.REVIEWED,
    color: COLORS.SUCCESS,
  },
};
