import axios, { AxiosError, AxiosHeaders } from 'axios';

type ApiParams = {
  baseURL?: string;
};

const fetchBaseURL = async (): Promise<string> => {
  try {
    const response = await fetch('/api/config');

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data?.keycloakUrl;
  } catch (error) {
    console.error('Error fetching baseURL', error);
    return 'localhost:4001';
  }
};

const getApiInstance = async ({ baseURL }: ApiParams) => {
  const defaultOptions = {
    baseURL: baseURL || (await fetchBaseURL()),
    withCredentials: true,
  };

  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use(async (config) => {
    config.headers = { ...config.headers } as AxiosHeaders;
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response?.data;
    },
    async (error) => {
      return Promise.reject(error as AxiosError);
    }
  );

  return instance;
};

export const keyCloakClient = getApiInstance({});
