import { FC } from 'react';
import { Modal, ModalFooter } from 'components/Modal';
import { useDialogState } from 'hooks/useDialogState';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../constants';
import { GroupedFiles } from 'pages/Calendar';
import { format } from 'date-fns';
import { DaySummary } from './summaryForm/DaySummary';
import { Summary } from 'api/queries/summary/useGetSummaries';

interface SummaryHistoryProps {
  dayDetails: GroupedFiles[];
  dialogState: ReturnType<typeof useDialogState>;
  summary: Summary;
}

export const SummaryHistory: FC<SummaryHistoryProps> = ({ dayDetails, dialogState, summary }) => {
  const { t } = useTranslation();

  const details = [
    { label: t('calendar.summary.results'), value: summary.description },
    { label: t('calendar.summary.recommendations'), value: summary.recommendations },
    {
      label: t('calendar.summaryHistory.createdBy'),
      value: `${summary.createdBy.firstName} ${summary.createdBy.lastName}`,
    },
    {
      label: t('calendar.summaryHistory.createdOn'),
      value: format(new Date(summary.createdAt), 'dd-MM-yyyy'),
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ bgcolor: COLORS.PRIMARY }}
        onClick={dialogState.open}
      >
        {t('calendar.summaryHistory.showHistory')}
      </Button>

      <Modal title={t('calendar.summary.caseSummary')} dialogState={dialogState}>
        <Stack
          flex={1}
          mb="40px"
          bgcolor={COLORS.BLUE_SECOND}
          borderRadius="12px"
          p="12px 24px 24px 24px"
          overflow="auto"
        >
          <Stack>
            {dayDetails.map((day, index) => (
              <DaySummary key={day.date} dayDetails={day} defaultOpen={index === 0} />
            ))}
          </Stack>

          <Stack gap="24px">
            {details.map((detail) => (
              <Stack key={detail.label} gap="12px" py="16px">
                <Typography variant="P4_R">{detail.label}</Typography>
                <Typography variant="P2_R">{detail.value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <ModalFooter>
          <Button variant="contained" color="secondary" onClick={dialogState.close}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
