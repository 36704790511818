export const ArrowDownIcon = () => (
  <svg
    style={{ cursor: 'pointer' }}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.1387 5.52781L8.00037 8.39031L10.862 5.52781C10.924 5.46587 10.9975 5.41673 11.0784 5.38321C11.1594 5.34969 11.2461 5.33244 11.3337 5.33244C11.4213 5.33244 11.508 5.34969 11.589 5.38321C11.6699 5.41673 11.7434 5.46587 11.8054 5.52781C11.8673 5.58975 11.9164 5.66328 11.95 5.74421C11.9835 5.82514 12.0007 5.91188 12.0007 5.99948C12.0007 6.08707 11.9835 6.17381 11.95 6.25474C11.9164 6.33567 11.8673 6.4092 11.8054 6.47114L8.47204 9.80448C8.41012 9.86646 8.3366 9.91563 8.25566 9.94918C8.17473 9.98273 8.08798 10 8.00037 10C7.91276 10 7.82601 9.98273 7.74508 9.94918C7.66415 9.91563 7.59062 9.86646 7.5287 9.80448L4.19537 6.47114C4.13343 6.4092 4.0843 6.33567 4.05077 6.25474C4.01725 6.17381 4 6.08707 4 5.99948C4 5.91188 4.01725 5.82514 4.05077 5.74421C4.0843 5.66328 4.13343 5.58975 4.19537 5.52781C4.25731 5.46587 4.33084 5.41674 4.41177 5.38321C4.4927 5.34969 4.57944 5.33244 4.66704 5.33244C4.75463 5.33244 4.84137 5.34969 4.9223 5.38321C5.00323 5.41674 5.07676 5.46587 5.1387 5.52781Z"
      fill="#749EC4"
    />
  </svg>
);
