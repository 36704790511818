import PatientsList from '../components/PatientsList';
import Box from '@mui/material/Box';
import { PatientListProvider } from '../contexts/PatientListProvider';
import { FilterBar } from '../components/FilterBar';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';

export const Dashboard = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <PatientListProvider>
        <Box sx={{ ml: 6, mr: 6, mt: 1 }}>
          <FilterBar />
          <Box sx={{ mt: 4 }}>
            <PatientsList />
          </Box>
        </Box>
      </PatientListProvider>
    </QueryParamProvider>
  );
};
