import { Scrollbars } from 'react-custom-scrollbars';
import { FC, forwardRef, useImperativeHandle, useRef } from 'react';

type CustomScrollbarProps = {
  thumbColor: string;
  trackColor: string;
  children: JSX.Element;
  selectedItemId?: string;
};

type ScrollbarRef = Scrollbars & {
  container: HTMLElement;
};

export const CustomScrollbar: FC<CustomScrollbarProps> = forwardRef<
  ScrollbarRef,
  CustomScrollbarProps
>(({ children, thumbColor, trackColor, selectedItemId }: CustomScrollbarProps, ref) => {
  const scrollbarsRef = useRef<ScrollbarRef>(null);
  // const { selectedFileId } = useParams();
  const animationRef = useRef<number | null>(null);

  const scrollTo = (targetValue: number, duration: number) => {
    if (scrollbarsRef.current) {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }

      const currentScrollTop = scrollbarsRef.current.getScrollTop();
      const delta = targetValue - currentScrollTop;
      const startTime = performance.now();

      const animation = () => {
        const elapsedTime = performance.now() - startTime;
        const progress = Math.min(1, elapsedTime / duration);
        const easedProgress = easeInOutQuad(progress);
        const newPosition = currentScrollTop + delta * easedProgress;

        if (scrollbarsRef.current) {
          scrollbarsRef.current.scrollTop(newPosition);
        }

        if (elapsedTime < duration) {
          animationRef.current = requestAnimationFrame(animation);
        } else {
          animationRef.current = null;
        }
      };

      animationRef.current = requestAnimationFrame(animation);
    }
  };

  const easeInOutQuad = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  const handleScrollPositionChange = () => {
    if (scrollbarsRef?.current) {
      const { current } = scrollbarsRef;

      const selectedFileIdElement: HTMLElement | null = current.container.querySelector(
        `[data-id="${selectedItemId}"]`
      );

      if (selectedFileIdElement) {
        const elementOffsetTop = selectedFileIdElement.offsetTop;
        scrollTo(elementOffsetTop - 20, 200);
      }
    }
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        ...scrollbarsRef.current,
        handleScrollPositionChange,
      }) as unknown as ScrollbarRef,
    [scrollbarsRef, scrollTo]
  );

  return (
    <Scrollbars
      ref={scrollbarsRef}
      thumbMinSize={40}
      renderTrackVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: trackColor,
            right: '12px',
            bottom: '24px',
            top: '24px',
            borderRadius: '3px',
            width: '6px',
          }}
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            width: '6px',
            borderRadius: '4px',
            backgroundColor: thumbColor,
          }}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
});
