import { createContext, ReactNode, useContext } from 'react';
import { PatientsListRO, usePatients } from '../api/queries/patient/usePatients';
import { REVIEW_STATUS } from './PatientListProvider';

type PatientSidebarProviderProps = {
  children: ReactNode;
};

const defaultData = {
  content: [],
  totalCount: 0,
};

type PatientSidebarContextRO = PatientsListRO;

export const PatientSidebarContext = createContext<PatientSidebarContextRO>(defaultData);

export const usePatientSidebarContext = () => {
  const patientSidebar = useContext(PatientSidebarContext);

  if (!patientSidebar) {
    throw new Error('PatientSidebarContext: No value provided');
  }

  return patientSidebar;
};

export const PatientSidebarProvider = ({ children }: PatientSidebarProviderProps) => {
  const { data: patientSidebarData } = usePatients({
    limit: 100,
    offset: 0,
    reviewStatus: REVIEW_STATUS.AWAITING_REVIEW,
  });

  return (
    <PatientSidebarContext.Provider value={patientSidebarData || defaultData}>
      {children}
    </PatientSidebarContext.Provider>
  );
};
