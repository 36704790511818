import { Button, IconButton, Stack, Typography } from '@mui/material';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { Modal, ModalFooter } from 'components/Modal';
import {
  DetectedMarkerLegend,
  MachineLearningMarkerLegend,
  NotDetectedMarkerLegend,
  UserFeedbackMarkerLegend,
} from 'components/Timeline/MarkerLegend';
import { useDialogState } from 'hooks/useDialogState';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const LegendSection: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant="P2_R">{t('markers.legend.timeline')}</Typography>
      <MachineLearningMarkerLegend />
      <NotDetectedMarkerLegend />
      <DetectedMarkerLegend />
      <UserFeedbackMarkerLegend />
    </Stack>
  );
};

export const MarkerReviewInfo: FC = () => {
  const dialogState = useDialogState();
  const { t } = useTranslation();

  const sections = [
    { label: t('markers.description.title'), content: <LegendSection /> },
    {
      label: t('markers.description.filters'),
      content: t('markers.description.filtersDescription'),
    },
    {
      label: t('markers.description.timeBar'),
      content: t('markers.description.timeBarDescription'),
    },
    {
      label: t('markers.description.reviewAndAdding'),
      content: t('markers.description.reviewAndAddingDescription'),
    },
  ];

  return (
    <>
      <IconButton sx={{ mr: '-10px', svg: { width: 26, height: 26 } }} onClick={dialogState.open}>
        <InfoIcon />
      </IconButton>

      <Modal title={t('markers.description.header')} dialogState={dialogState}>
        <Stack flex={1} gap="24px" px="24px">
          {sections.map((section, index) => (
            <Stack key={index} gap="12px">
              <Typography variant="P1_SB">{section.label}</Typography>
              <Typography variant="P2_R">{section.content}</Typography>
            </Stack>
          ))}
        </Stack>
        <ModalFooter>
          <Button variant="outlined" color="secondary" onClick={dialogState.close}>
            {t('common.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={dialogState.close}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
