import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { COLORS } from '../../constants';

interface BarProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  width: string;
}

export const Bar: React.FC<PropsWithChildren<BarProps>> = ({ onClick, width, children }) => {
  return (
    <Stack
      onClick={onClick}
      borderRadius="2px"
      bgcolor={COLORS.BLUE_PRIMARY}
      height="16px"
      width={width}
      position="relative"
      sx={{ '&:hover': { bgcolor: COLORS.SECONDARY }, cursor: 'pointer' }}
    >
      {children}
    </Stack>
  );
};
