import Box from '@mui/material/Box';
import { PlayPauseButton } from '../PlayPauseButton';
import { FullscreenButton } from '../FullscreenButton';
import { VideoPlayerTime } from '../VideoPlayerTime';
import { COLORS } from '../../../constants';
import { VideoPlayerTimeline } from '../VideoPlayerTimeline';
import { SyntheticEvent } from 'react';
import { useVideoPlayerContext } from '../../../contexts/VideoPlayerContext';

type FullscreenControlsProps = {
  playPauseHandler: () => void;
  seekHandler: (e: SyntheticEvent<Element, Event> | Event) => void;
  seekMouseUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
  onSeekMouseDownHandler: (e: SyntheticEvent | Event) => void;
  isFullscreenActive: boolean;
  handleToggleFullscreen: () => void;
};

export const FullscreenControls = ({
  seekHandler,
  seekMouseUpHandler,
  onSeekMouseDownHandler,
  isFullscreenActive,
  playPauseHandler,
  handleToggleFullscreen,
}: FullscreenControlsProps) => {
  const {
    videoState: { playing, played },
  } = useVideoPlayerContext();

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        px: '70px',
        py: '32px',
        background: 'rgba(14, 98, 150, 0.80)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <PlayPauseButton size="large" handlePlayPause={playPauseHandler} isPlaying={playing} />
        <VideoPlayerTimeline
          color="secondary"
          played={played}
          onSeekMouseDownHandler={onSeekMouseDownHandler}
          seekHandler={seekHandler}
          seekMouseUpHandler={seekMouseUpHandler}
        />
        <Box
          sx={{
            width: '40px',
            height: '40px',
            svg: {
              width: '40px',
              height: '40px',
            },
          }}
        >
          <FullscreenButton
            isActive={isFullscreenActive}
            handleToggleFullscreen={handleToggleFullscreen}
          />
        </Box>
      </Box>
      <Box sx={{ color: COLORS.WHITE, pt: 1, pl: '48px' }}>
        <VideoPlayerTime />
      </Box>
    </Box>
  );
};
