export const DropdownIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path
        id="Vector"
        d="M11.7081 12.7917L16.0006 17.0855L20.2931 12.7917C20.386 12.6988 20.4963 12.6251 20.6177 12.5748C20.7391 12.5245 20.8692 12.4987 21.0006 12.4987C21.132 12.4987 21.2621 12.5245 21.3835 12.5748C21.5048 12.6251 21.6151 12.6988 21.7081 12.7917C21.801 12.8846 21.8747 12.9949 21.9249 13.1163C21.9752 13.2377 22.0011 13.3678 22.0011 13.4992C22.0011 13.6306 21.9752 13.7607 21.9249 13.8821C21.8747 14.0035 21.801 14.1138 21.7081 14.2067L16.7081 19.2067C16.6152 19.2997 16.5049 19.3734 16.3835 19.4238C16.2621 19.4741 16.132 19.5 16.0006 19.5C15.8691 19.5 15.739 19.4741 15.6176 19.4238C15.4962 19.3734 15.3859 19.2997 15.2931 19.2067L10.2931 14.2067C10.2001 14.1138 10.1264 14.0035 10.0762 13.8821C10.0259 13.7607 10 13.6306 10 13.4992C10 13.3678 10.0259 13.2377 10.0762 13.1163C10.1264 12.9949 10.2001 12.8846 10.2931 12.7917C10.386 12.6988 10.4963 12.6251 10.6177 12.5748C10.7391 12.5245 10.8692 12.4987 11.0006 12.4987C11.132 12.4987 11.2621 12.5245 11.3835 12.5748C11.5048 12.6251 11.6151 12.6988 11.7081 12.7917Z"
        fill="white"
      />
    </g>
  </svg>
);
