import Box from '@mui/material/Box';
import { COLORS } from '../../../constants';
import { PauseIcon } from '../../../assets/icons/PauseIcon';
import { PlayIcon } from '../../../assets/icons/PlayIcon';

type PlayPauseButtonProps = {
  isPlaying: boolean;
  size: 'small' | 'large';
  handlePlayPause: () => void;
};

export const PlayPauseButton = ({ isPlaying, size, handlePlayPause }: PlayPauseButtonProps) => {
  return (
    <Box
      onClick={handlePlayPause}
      sx={{
        cursor: 'pointer',
        minHeight: size === 'large' ? '40px' : '24px',
        minWidth: size === 'large' ? '40px' : '24px',
        background: COLORS.WHITE,
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #034986',
        svg: {
          width: size === 'large' ? '16px' : '10px',
          height: size === 'large' ? '16px' : '10px',
        },
      }}
    >
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </Box>
  );
};
