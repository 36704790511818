export const ArrowUpIcon = () => (
  <svg
    style={{ cursor: 'pointer' }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8632 9.80422L8.00158 6.94172L5.13992 9.80422C5.07798 9.86616 5.00444 9.9153 4.92351 9.94882C4.84258 9.98234 4.75584 9.99959 4.66825 9.99959C4.58065 9.99959 4.49391 9.98234 4.41298 9.94882C4.33205 9.9153 4.25852 9.86616 4.19658 9.80422C4.13464 9.74228 4.08551 9.66875 4.05199 9.58782C4.01846 9.50689 4.00121 9.42015 4.00121 9.33256C4.00121 9.24496 4.01846 9.15822 4.05199 9.07729C4.08551 8.99636 4.13464 8.92283 4.19658 8.86089L7.52992 5.52756C7.59183 5.46557 7.66536 5.4164 7.74629 5.38285C7.82722 5.3493 7.91397 5.33203 8.00158 5.33203C8.08919 5.33203 8.17594 5.3493 8.25688 5.38285C8.33781 5.4164 8.41133 5.46557 8.47325 5.52756L11.8066 8.86089C11.8685 8.92283 11.9177 8.99636 11.9512 9.07729C11.9847 9.15822 12.002 9.24496 12.002 9.33256C12.002 9.42015 11.9847 9.50689 11.9512 9.58782C11.9177 9.66875 11.8685 9.74228 11.8066 9.80422C11.7446 9.86616 11.6711 9.9153 11.5902 9.94882C11.5093 9.98234 11.4225 9.99959 11.3349 9.99959C11.2473 9.99959 11.1606 9.98234 11.0797 9.94882C10.9987 9.9153 10.9252 9.86616 10.8632 9.80422Z"
      fill="#212121"
    />
  </svg>
);
