export const PlayerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.333333" y="0.333333" width="31.3333" height="31.3333" rx="15.6667" fill="white" />
    <rect
      x="0.333333"
      y="0.333333"
      width="31.3333"
      height="31.3333"
      rx="15.6667"
      stroke="#034986"
      strokeWidth="0.666667"
    />
    <path
      d="M23.4807 15.7002C23.7115 15.8334 23.7115 16.1666 23.4807 16.2998L12.5193 22.6284C12.2885 22.7616 12 22.5951 12 22.3285V9.67147C12 9.40495 12.2885 9.23837 12.5193 9.37163L23.4807 15.7002Z"
      fill="#034986"
    />
  </svg>
);
