import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';
import { REVIEW_STATUS } from '../../../contexts/PatientListProvider';

export enum UPLOADER_TYPE {
  PARENT = 'PARENT',
  THIRD_PARTY = 'THIRD_PARTY',
}

export type PatientRO = {
  patientId: string;
  firstName: string;
  lastName: string;
  parentFirstName: string;
  parentLastName: string;
  caseName: string;
  reviewResult: string;
  reviewStatus: string;
  filesToReview: number;
  totalFilesCount: number;
  recentlyUploadedFileDate: string;
  fileReviewStatus: REVIEW_STATUS;
  filesToReviewCount: number;
  doctorFirstName: string;
  doctorLastName: string;
  doctorOrganisationName: string;
  reviewerFirstName: string;
  reviewerLastName: string;
  uploader: {
    id: string;
    type: UPLOADER_TYPE;
    name: string;
  };
};

export type PatientsListRO = {
  content: PatientRO[];
  totalCount: number;
};

export enum PATIENT_LIST_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum PATIENT_LIST_SORT {
  NAME = 'name',
  RESULT = 'result',
  REVIEW_STATUS = 'review_status',
  FILES_TO_REVIEW = 'files_to_review',
  DATE = 'date',
}

type usePatientsProps = {
  name?: string;
  from?: string;
  to?: string;
  onlyMyPatients?: boolean;
  reviewStatus?: REVIEW_STATUS;
  limit?: number;
  offset?: number;
  sort?: PATIENT_LIST_SORT;
  order?: PATIENT_LIST_ORDER;
};

export const usePatients = (props: usePatientsProps) =>
  useQuery({
    queryKey: [QUERY_KEYS.PATIENTS, props],
    queryFn: () => verification(props),
  });

const verification = async (props: usePatientsProps): Promise<PatientsListRO> => {
  const { name, from, to, reviewStatus, onlyMyPatients, limit, offset, sort, order } = props;

  return baseClient({
    url: `/patients`,
    method: 'GET',
    params: {
      name,
      from,
      to,
      review_status: reviewStatus,
      only_my_patients: onlyMyPatients,
      limit,
      offset,
      sort,
      order,
    },
  });
};
