import { Box, Drawer, List, styled, Typography } from '@mui/material';
import { COLORS } from '../../constants';
import { ArrowLeft } from '../../assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { BackIcon } from '../../assets/icons/BackIcon';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientSidebarContext } from '../../contexts/PatientSidebarProvider';
import { ReviewedPatientItemList } from './ReviewedPatientItemList';
import { CustomScrollbar } from '../CustomScrollbar';
import { getLastDashboardFiltersUrl } from '../../utils';
import { PatientListItem } from '../PatientsList/PatientListItem';
import { Thumbnails } from './Thumbnails';
import { useFileParams } from 'hooks/useFileParams';

const SIZE = {
  MAIN: 256,
  MAIN_CLOSED: 80,
  SUB: 248,
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { patientId, selectedFileId } = useFileParams();

  const filesListRef = useRef<HTMLUListElement | null>(null);
  const patientListRef = useRef<HTMLUListElement | null>(null);

  const patientListScrollbarRef = useRef<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const { content } = useContext(PatientSidebarContext);

  const isPatientOnList = content?.find((item) => item.patientId === patientId);

  const toggleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMainScrollbarPosition = () => {
    if (patientListScrollbarRef?.current) {
      patientListScrollbarRef?.current?.handleScrollPositionChange?.();
    }
  };

  const handleBackButtonClick = () => {
    navigate(getLastDashboardFiltersUrl());
  };

  const widthSize = isMenuOpen ? SIZE.MAIN + SIZE.SUB : SIZE.MAIN_CLOSED + SIZE.SUB;

  const isFilePage = window.location.pathname.includes('file');

  return (
    <Box
      sx={{
        minWidth: widthSize,
        marginRight: '48px',
      }}
    >
      <Drawer
        className="sidebar"
        variant="permanent"
        sx={{
          display: 'flex',
          flexShrink: 0,
          [`& > .MuiDrawer-paper`]: {
            zIndex: 500,
            pt: '96px',
            ml: '48px',
            color: COLORS.WHITE,
            border: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', py: 1.5, alignItems: 'center' }}>
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: COLORS.BLACK_PRIMARY,
            }}
            onClick={handleBackButtonClick}
          >
            <ArrowLeft />
          </Box>
          <Typography
            sx={{
              fontSize: '1.25rem',
              ml: 1,
              color: COLORS.BLUE_THIRD,
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={handleBackButtonClick}
          >
            {t('patient.backToAllPatients')}
          </Typography>
        </Box>
        <GradientBoxStyled
          sx={{
            '&::before': {
              width: isMenuOpen ? '236px' : '80px',
            },
          }}
        >
          {!isMenuOpen && <SidebarClosedStyled />}
          {isMenuOpen && (
            <SidebarStyled ref={patientListRef}>
              <CustomScrollbar
                selectedItemId={patientId}
                //@ts-ignore
                ref={patientListScrollbarRef}
                thumbColor={COLORS.BLUE_PRIMARY}
                trackColor={COLORS.PRIMARY}
              >
                <List sx={{ mr: 4, ml: 3, mt: 2 }}>
                  {!isPatientOnList && <ReviewedPatientItemList />}
                  {content?.map((patient) => (
                    <PatientListItem
                      key={patient.patientId}
                      parentRef={patientListRef}
                      handleScrollbarPosition={handleMainScrollbarPosition}
                      patient={patient}
                      selectedPatientId={patientId}
                    />
                  ))}
                </List>
              </CustomScrollbar>
            </SidebarStyled>
          )}
          <SecondSidebarStyled ref={filesListRef}>
            {isFilePage ? (
              <Thumbnails
                patientId={patientId}
                selectedFileId={selectedFileId}
                filesListRef={filesListRef}
              />
            ) : (
              <Typography variant="h2" color={COLORS.SECONDARY} p="24px 10px">
                {t('calendar.clickOnDay')}
              </Typography>
            )}
          </SecondSidebarStyled>
        </GradientBoxStyled>
        <Box
          sx={{
            position: 'absolute',
            bottom: '24px',
            left: '0px',
            width: '180px',
            padding: '6px 24px 24px',
            zIndex: 100,
          }}
        >
          <CloseIconStyled
            sx={{ transform: isMenuOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
            onClick={toggleMenuOpen}
          >
            <BackIcon />
          </CloseIconStyled>
        </Box>
      </Drawer>
    </Box>
  );
};

const GradientBoxStyled = styled(Box)(() => ({
  position: 'relative',
  width: '100% ',
  display: 'flex',
  height: 'calc(100% - 90px)',
  boxSizing: 'border-box',
  '&::before': {
    content: '""',
    position: 'absolute',
    height: '160px',
    bottom: 0,
    left: 0,
    background:
      'linear-gradient(180deg,rgba(3,74,135,0) 0%, rgba(16,105,154,1) 60%, rgba(16,105,154,1) 100%)',
    zIndex: 1,
    pointerEvents: 'none',
    borderBottomLeftRadius: '24px',
  },
}));

const CloseIconStyled = styled(Box)(() => ({
  background: COLORS.WHITE,
  width: '32px',
  height: '32px',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 100,
}));

const SidebarClosedStyled = styled(Box)(() => ({
  overflow: 'auto',
  height: '100%',
  width: '80px',
  px: 3,
  py: 0.5,
  background: 'linear-gradient(90deg, #034A87 2.93%, #10699A 97.2%)',
  borderTopLeftRadius: '24px',
  borderBottomLeftRadius: '24px',
}));

const SidebarStyled = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
  width: SIZE.MAIN,
  background: 'linear-gradient(90deg, #034A87 2.93%, #10699A 97.2%)',
  borderTopLeftRadius: '24px',
  borderBottomLeftRadius: '24px',
}));

const SecondSidebarStyled = styled(Box)(() => ({
  height: '100%',
  width: SIZE.SUB,
  background: COLORS.PRIMARY,
  paddingLeft: '24px',
  paddingRight: '24px',
  borderTopRightRadius: '24px',
  borderBottomRightRadius: '24px',
  'div &:last-item': {
    marginBottom: '48px',
  },
}));

export const baseStyles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  padding: '8px 12px 8px 24px',
};

export const activeStyles = {
  color: COLORS.BLACK_PRIMARY,
};

export const selectedItemStyles = {
  borderRadius: '10px',
  p: 0,
  m: 0,
  [`&.Mui-selected`]: {
    position: 'relative',
    background: COLORS.PRIMARY,
  },
  [`&:hover`]: {
    position: 'relative',
    background: '#bbd0e2!important',
    color: 'black',
  },
};
