import Box from '@mui/material/Box';
import { STATUS_VARIANT_DETAILS } from './constants';
import { REVIEW_STATUS } from '../../contexts/PatientListProvider';
import { useTranslation } from 'react-i18next';

interface StatusProps {
  variant: REVIEW_STATUS;
}

export const Status = ({ variant }: StatusProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: '24px' }}>
      <Box
        sx={{
          mr: 0.5,
          height: '24px',
          width: '24px',
          minWidth: '24px',
          background: `${STATUS_VARIANT_DETAILS[variant]?.color}`,
          borderRadius: '40px',
        }}
      />
      {t(`status.${STATUS_VARIANT_DETAILS[variant]?.label}`)}
    </Box>
  );
};
