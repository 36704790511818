import { format } from 'date-fns';

export const dateToQueryParam = (date: string) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const formatDuration = (seconds: number) => {
  const date = new Date(seconds * 1000);

  if (seconds >= 3600) {
    return format(date, 'HH:mm');
  }

  return format(date, 'mm:ss');
};
