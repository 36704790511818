import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';
import { REVIEW_RESULT_STATUS } from '../patient/constants';

export interface Summary {
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  patientId: string;
  description: string;
  recommendations: string;
  totalReviews: number;
  fileIds: string[];
  reviewResults: Record<REVIEW_RESULT_STATUS, number | undefined>;
}

export const useGetSummaries = (patientId: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.SUMMARIES, patientId],
    queryFn: () => getSummaries(patientId),
  });

const getSummaries = (patientId: string): Promise<{ summaries: Summary[] }> => {
  return baseClient({
    url: `/patients/${patientId}/summaries`,
    method: 'GET',
  });
};
