import { InputAdornment, OutlinedInput, Typography, IconButton, Stack } from '@mui/material';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { PatientListContext } from '../../contexts/PatientListProvider';

export const SearchInput = () => {
  const { query, setQuery } = useContext(PatientListContext);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(query.name);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== query.name) {
        setQuery({
          name: value?.length ? value : null,
          page: 0,
        });
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [value, setQuery]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const clearValue = () => {
    setValue('');
  };

  const handleInputFocus = () => {
    if (inputRef?.current) {
      const textField = inputRef.current.querySelector(`input`);
      textField && textField.focus();
    }
  };

  return (
    <Stack>
      <Typography variant="label">{t('search.label')}</Typography>
      <OutlinedInput
        ref={inputRef}
        id="search"
        sx={{
          width: '296px',
        }}
        endAdornment={
          <InputAdornment position="end">
            {value?.length ? (
              <IconButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={clearValue}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleInputFocus}>
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        placeholder={t('search.here')}
        size="small"
        value={value}
        onChange={handleSearchChange}
      />
    </Stack>
  );
};
