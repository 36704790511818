export const TrashIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 5.31836H7.5V4.56836C7.5 3.97162 7.73705 3.39933 8.15901 2.97737C8.58097 2.55541 9.15326 2.31836 9.75 2.31836H14.25C14.8467 2.31836 15.419 2.55541 15.841 2.97737C16.2629 3.39933 16.5 3.97162 16.5 4.56836V5.31836H20.25C20.4489 5.31836 20.6397 5.39738 20.7803 5.53803C20.921 5.67868 21 5.86945 21 6.06836C21 6.26727 20.921 6.45804 20.7803 6.59869C20.6397 6.73934 20.4489 6.81836 20.25 6.81836H19.5V20.3184C19.5 20.7162 19.342 21.0977 19.0607 21.379C18.7794 21.6603 18.3978 21.8184 18 21.8184H6C5.60218 21.8184 5.22064 21.6603 4.93934 21.379C4.65804 21.0977 4.5 20.7162 4.5 20.3184V6.81836H3.75C3.55109 6.81836 3.36032 6.73934 3.21967 6.59869C3.07902 6.45804 3 6.26727 3 6.06836C3 5.86945 3.07902 5.67868 3.21967 5.53803C3.36032 5.39738 3.55109 5.31836 3.75 5.31836ZM15 4.56836C15 4.36945 14.921 4.17868 14.7803 4.03803C14.6397 3.89738 14.4489 3.81836 14.25 3.81836H9.75C9.55109 3.81836 9.36032 3.89738 9.21967 4.03803C9.07902 4.17868 9 4.36945 9 4.56836V5.31836H15V4.56836ZM6 20.3184H18V6.81836H6V20.3184ZM13.5 10.5684V16.5684C13.5 16.7673 13.579 16.958 13.7197 17.0987C13.8603 17.2393 14.0511 17.3184 14.25 17.3184C14.4489 17.3184 14.6397 17.2393 14.7803 17.0987C14.921 16.958 15 16.7673 15 16.5684V10.5684C15 10.3694 14.921 10.1787 14.7803 10.038C14.6397 9.89738 14.4489 9.81836 14.25 9.81836C14.0511 9.81836 13.8603 9.89738 13.7197 10.038C13.579 10.1787 13.5 10.3694 13.5 10.5684ZM9 10.5684V16.5684C9 16.7673 9.07902 16.958 9.21967 17.0987C9.36032 17.2393 9.55109 17.3184 9.75 17.3184C9.94891 17.3184 10.1397 17.2393 10.2803 17.0987C10.421 16.958 10.5 16.7673 10.5 16.5684V10.5684C10.5 10.3694 10.421 10.1787 10.2803 10.038C10.1397 9.89738 9.94891 9.81836 9.75 9.81836C9.55109 9.81836 9.36032 9.89738 9.21967 10.038C9.07902 10.1787 9 10.3694 9 10.5684Z"
        fill="currentColor"
      />
    </svg>
  );
};
