import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REVIEW_RESULT_STATUS } from '../patient/constants';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '../index';

type REVIEW_DTO = {
  patientId: string;
  reviewResult: REVIEW_RESULT_STATUS;
  seizureType: string | null;
  description?: string;
};

export const useReview = ({ isNew }: { isNew: boolean }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      markerId,
      reviewData,
    }: {
      markerId: string;
      reviewData: REVIEW_DTO;
    }): Promise<void> => {
      await submitReviewData(markerId, reviewData, isNew);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENTS] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
    },
  });
};

const submitReviewData = async (
  markerId: string,
  data: REVIEW_DTO,
  isNew: boolean
): Promise<void> => {
  return baseClient({
    url: `/markers/${markerId}/review`,
    method: isNew ? 'POST' : 'PUT',
    data,
  });
};
