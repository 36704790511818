export const GermanFlagIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1412_14484)">
      <path
        d="M0.997559 10.7835C2.1279 13.8299 5.06025 16.0009 8.49996 16.0009C11.9397 16.0009 14.872 13.8299 16.0024 10.7835L8.49996 10.0879L0.997559 10.7835Z"
        fill="#FFDA44"
      />
      <path
        d="M8.49996 0C5.06025 0 2.1279 2.171 0.997559 5.21741L8.49996 5.91303L16.0024 5.21737C14.872 2.171 11.9397 0 8.49996 0Z"
        fill="black"
      />
      <path
        d="M0.997594 5.2168C0.676031 6.08345 0.5 7.02083 0.5 7.99939C0.5 8.97795 0.676031 9.91533 0.997594 10.782H16.0024C16.324 9.91533 16.5 8.97795 16.5 7.99939C16.5 7.02083 16.324 6.08345 16.0024 5.2168H0.997594Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1412_14484">
        <rect x="0.5" width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
