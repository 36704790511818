import { Stack, Typography, IconButton, Collapse } from '@mui/material';
import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import { Timeline } from 'components/Timeline';
import { Timestamps } from 'components/Timeline/Timestamps';
import { COLORS } from '../../../constants';
import { t } from 'i18next';
import { useState } from 'react';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';

interface GeneralSectionProps {
  currentDateFiles?: PatientFileRO[];
  duration: number;
  totalFilesDuration?: number;
}

export const GeneralSection = ({
  currentDateFiles,
  duration,
  totalFilesDuration,
}: GeneralSectionProps) => {
  const [isExpanded, setExpanded] = useState(false);

  if (!currentDateFiles) {
    return null;
  }

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Typography variant="P3_SB">{t('patient.sessionPreview')}</Typography>
        <IconButton
          onClick={() => setExpanded(!isExpanded)}
          sx={{
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            'svg path': {
              fill: COLORS.BLACK_PRIMARY,
            },
          }}
        >
          <ArrowDownIcon />
        </IconButton>
      </Stack>

      <Collapse in={isExpanded} sx={{ px: 2 }}>
        <Timeline files={currentDateFiles} totalFilesDuration={totalFilesDuration!} />
        <Timestamps totalFilesDuration={duration} />
      </Collapse>
    </Stack>
  );
};
