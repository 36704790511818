import { FC } from 'react';
import { Modal, ModalFooter } from 'components/Modal';
import { useDialogState } from 'hooks/useDialogState';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'assets/icons/InfoIcon';

export const CalendarPageInfo: FC = () => {
  const { t } = useTranslation();
  const dialogState = useDialogState();

  return (
    <>
      <IconButton onClick={dialogState.open}>
        <InfoIcon />
      </IconButton>
      <Modal title={t('calendar.guide.title')} dialogState={dialogState}>
        <Stack flex={1} p="0 24px" gap="24px">
          <Stack gap="12px">
            <Typography variant="P1_SB">{t('calendar.guide.calendar')}</Typography>
            <Typography variant="P2_R">{t('calendar.guide.calendarDescription')}</Typography>
          </Stack>

          <Stack gap="12px">
            <Typography variant="P1_SB">{t('calendar.guide.bottomPanel')}</Typography>
            <Typography variant="P2_R">{t('calendar.guide.bottomPanelDescription')}</Typography>
          </Stack>
        </Stack>
        <ModalFooter>
          <Button variant="outlined" color="secondary" onClick={dialogState.close}>
            {t('common.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={dialogState.close}>
            {t('common.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
