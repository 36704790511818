export enum REVIEW_RESULT_STATUS {
  DETECTED = 'DETECTED',
  NOT_DETECTED = 'NOT_DETECTED',
  UNCERTAIN = 'UNCERTAIN',
  SKIPPED = 'SKIPPED',
}

export enum SEIZURE {
  FOCAL = 'FOCAL',
  GENERALIZED = 'GENERALIZED',
  UNKNOWN = 'UNKNOWN',
  UNCLASSIFIED = 'UNCLASSIFIED',
}

export enum FOCAL {
  MOTOR = 'MOTOR',
  NONMOTOR = 'NONMOTOR',
  FOCAL_BILATERAL_TONIC_CLONIC = 'FOCAL_BILATERAL_TONIC_CLONIC',
}

export enum FOCAL_AWARE {
  AWARE = 'AWARE',
  IMPAIRED_AWARENESS = 'IMPAIRED_AWARENESS',
}

export enum FOCAL_MOTOR {
  AUTOMATISM = 'AUTOMATISM',
  ATONIC = 'ATONIC',
  CLONIC = 'CLONIC',
  EPILEPTIC_SPASM = 'EPILEPTIC_SPASM',
  HYPERKINETIC = 'HYPERKINETIC',
  MYOCLONIC = 'MYOCLONIC',
  TONIC = 'TONIC',
}

export enum FOCAL_NONMOTOR {
  AUTONOMIC = 'AUTONOMIC',
  BEHAVIOR_ARREST = 'BEHAVIOR_ARREST',
  COGNITIVE = 'COGNITIVE',
  EMOTIONAL = 'EMOTIONAL',
  SENSORY = 'SENSORY',
}

export enum GENERALIZED {
  MOTOR = 'MOTOR',
  NONMOTOR = 'NONMOTOR',
}

export enum GENERALIZED_MOTOR {
  TONIC_CLONIC = 'TONIC_CLONIC',
  CLONIC = 'CLONIC',
  TONIC = 'TONIC',
  MYOCLONIC = 'MYOCLONIC',
  MYOCLONIC_TONIC_CLONIC = 'MYOCLONIC_TONIC_CLONIC',
  MYOCLONIC_ATONIC = 'MYOCLONIC_ATONIC',
  ATONIC = 'ATONIC',
  EPILEPTIC_SPASM = 'EPILEPTIC_SPASM',
}

export enum GENERALIZED_NONMOTOR {
  TYPICAL = 'TYPICAL',
  ATYPICAL = 'ATYPICAL',
  MYOCLONIC = 'MYOCLONIC',
  EYELID_MYOCLONIA = 'EYELID_MYOCLONIA',
}

export enum UNKNOWN {
  MOTOR = 'MOTOR',
  NONMOTOR = 'NONMOTOR',
}

export enum UNKNOWN_MOTOR {
  TONIC_CLONIC = 'TONIC_CLONIC',
  EPILEPTIC_SPASM = 'EPILEPTIC_SPASM',
}

export enum UNKNOWN_NONMOTOR {
  BEHAVIOR_ARREST = 'BEHAVIOR_ARREST',
}

export type MotorType = FOCAL | GENERALIZED | UNKNOWN;

export type SeizureType =
  | FOCAL.MOTOR
  | FOCAL.NONMOTOR
  | FOCAL.FOCAL_BILATERAL_TONIC_CLONIC
  | GENERALIZED.MOTOR
  | GENERALIZED.NONMOTOR
  | UNKNOWN.MOTOR
  | UNKNOWN.NONMOTOR
  | SEIZURE.UNCLASSIFIED;

export const REVIEW_SEIZURE_TYPE_LABELS = {
  [SEIZURE.FOCAL]: {
    [FOCAL.MOTOR]: Object.values(FOCAL_MOTOR) as FOCAL_MOTOR[],
    [FOCAL.NONMOTOR]: Object.values(FOCAL_NONMOTOR) as FOCAL_NONMOTOR[],
    [FOCAL.FOCAL_BILATERAL_TONIC_CLONIC]: [],
  },
  [SEIZURE.GENERALIZED]: {
    [GENERALIZED.MOTOR]: Object.values(GENERALIZED_MOTOR) as GENERALIZED_MOTOR[],
    [GENERALIZED.NONMOTOR]: Object.values(GENERALIZED_NONMOTOR) as GENERALIZED_NONMOTOR[],
  },
  [SEIZURE.UNKNOWN]: {
    [UNKNOWN.MOTOR]: Object.values(UNKNOWN_MOTOR) as UNKNOWN_MOTOR[],
    [UNKNOWN.NONMOTOR]: Object.values(UNKNOWN_NONMOTOR) as UNKNOWN_NONMOTOR[],
  },
  [SEIZURE.UNCLASSIFIED]: [],
};
