import { useModal } from 'hooks/useModal';
import { ModalContent } from '../../GlobalModal';
import { Box, Button, Typography } from '@mui/material';
import { COLORS } from '../../../../constants';
import { CloseModalIcon } from '../../CloseModalIcon';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useSkipAllReview } from '../../../../api/queries/patient/useSkipAllReview';
import { useParams } from 'react-router-dom';

export const SkipAllVideosModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { patientId } = useParams();

  const {
    handleSubmit,
    setError,
    formState: { isLoading, isSubmitting },
  } = useForm();

  const skipAllReview = useSkipAllReview();

  const onSubmit = async () => {
    try {
      if (!patientId) return;
      await skipAllReview.mutateAsync({ patientId });
      closeModal();
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errCode = err?.response?.data?.code;
        setError('customError', {
          type: 'custom',
          message: errCode ? t(`error.${errCode}`) : err.message,
        });
      }
    }
  };

  return (
    <ModalContent component="form" onSubmit={handleSubmit(onSubmit)}>
      <CloseModalIcon />
      <Typography sx={{ mt: 2, color: COLORS.WHITE, fontSize: '1.25rem', fontWeight: '500' }}>
        {t('modal.skipAllVideosModal.title')}
      </Typography>
      <Typography sx={{ mt: 4, color: COLORS.WHITE, whiteSpace: 'pre-line' }}>
        {t('modal.skipAllVideosModal.description')}
      </Typography>
      <Box sx={{ mt: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={closeModal}
          sx={{ minWidth: '210px', maxHeight: '50px' }}
        >
          {t('modal.skipAllVideosModal.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{ minWidth: '210px', maxHeight: '50px' }}
          disabled={isLoading || isSubmitting}
        >
          {t('modal.skipAllVideosModal.submit')}
        </Button>
      </Box>
    </ModalContent>
  );
};
