import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

type useProfileSettingsProps = {
  firstName: string;
  lastName: string;
};

export const useProfileSettings = () =>
  useMutation({
    mutationFn: async (payload: useProfileSettingsProps): Promise<void> => {
      await profileSettings(payload);
    },
  });

const profileSettings = async (data: useProfileSettingsProps): Promise<void> => {
  return baseClient({
    url: `/doctors/account-details`,
    method: 'PUT',
    data,
  });
};
