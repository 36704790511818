import { Stack, Tooltip, Typography } from '@mui/material';
import { COLORS } from '../../constants';
import { FC, ReactNode } from 'react';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';

interface CalendarSummaryProps {
  files: PatientFileRO[];
  summaryButton: ReactNode;
}

export const CalendarSummary: FC<CalendarSummaryProps> = ({ files, summaryButton }) => {
  const { t } = useTranslation();

  const detectedByML = files.filter((file) =>
    file.markers.some((marker) => marker.type === 'ML_CREATED')
  ).length;
  const detectedByReviewer = files.filter((file) =>
    file.markers.some(
      (marker) => marker.doctorReview?.reviewResult === REVIEW_RESULT_STATUS.DETECTED
    )
  ).length;

  const detectedByReviewerPercentage = Math.round(
    (detectedByReviewer / (detectedByML + detectedByReviewer)) * 100
  );

  return (
    <Stack p="24px" gap="12px">
      <Typography variant="h1">{t('calendar.summary.title')}</Typography>
      <Stack direction="row" gap="40px">
        <Stack position="relative" width="fit-content" alignItems="center" justifyContent="center">
          <MarkersChart reviewerPercentage={detectedByReviewerPercentage} />
          <Stack textAlign="center" position="absolute">
            <Typography variant="h1">{detectedByML + detectedByReviewer}</Typography>
            <Typography variant="P3_R">{t('calendar.summary.totalMarks')}</Typography>
          </Stack>
        </Stack>

        <Stack justifyContent="space-between">
          <ChartLegend detectedByML={detectedByML} detectedByReviewer={detectedByReviewer} />

          <Stack mb="12px" direction="row" gap="8px" alignItems="center">
            {summaryButton}
            <Tooltip title={t('calendar.summary.unreviewedVideosTooltip')} arrow placement="right">
              <Stack>
                <InfoIcon />
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const MarkersChart: FC<{ reviewerPercentage: number }> = ({ reviewerPercentage }) => {
  const circleProps = {
    cx: 21,
    cy: 21,
    r: '15.91549430918954',
    fill: 'transparent',
  };

  return (
    <svg width="170px" height="170px" viewBox="0 0 42 42">
      <circle {...circleProps} stroke={COLORS.SECONDARY} strokeWidth="4" />
      {reviewerPercentage && (
        <circle
          {...circleProps}
          stroke={COLORS.PRIMARY}
          strokeWidth="4.3"
          strokeDasharray={`${reviewerPercentage} ${100 - reviewerPercentage}`}
          strokeDashoffset="25"
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};

interface ChartLegendItem {
  detectedByReviewer: number;
  detectedByML: number;
}

const ChartLegend: FC<ChartLegendItem> = ({ detectedByML, detectedByReviewer }) => {
  const { t } = useTranslation();

  const legend = [
    { color: COLORS.PRIMARY, name: t('calendar.detectedByReviewer'), value: detectedByReviewer },
    { color: COLORS.SECONDARY, name: t('calendar.mlDetected'), value: detectedByML },
  ];

  return (
    <Stack gap="16px" mt="10px">
      {legend.map((item, index) => (
        <Stack gap="8px" direction="row" alignItems="center" key={index}>
          <Stack width="16px" height="16px" bgcolor={item.color} borderRadius="50%" />
          <Typography variant="P4_R" color={COLORS.GRAY_PRIMARY}>
            {item.name}:{' '}
            <Typography color={COLORS.BLACK_PRIMARY} variant="P4_R">
              {item.value}
            </Typography>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
