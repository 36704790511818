import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

interface SignUpPayload {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  invitationCode: string;
}

export const useSignUp = () => {
  return useMutation({
    mutationFn: signUp,
  });
};

const signUp = (data: SignUpPayload) => {
  return baseClient({
    url: '/doctors/sign-up',
    method: 'POST',
    data,
  });
};
