import Box from '@mui/material/Box';
import { FullscreenOffIcon } from '../../../assets/icons/FullscreenOffIcon';
import { FullscreenOnIcon } from '../../../assets/icons/FullscreenOnIcon';

type FullscreenButtonProps = {
  isActive: boolean;
  handleToggleFullscreen: () => void;
};

export const FullscreenButton = ({ isActive, handleToggleFullscreen }: FullscreenButtonProps) => {
  return (
    <Box
      onClick={handleToggleFullscreen}
      sx={{
        cursor: 'pointer',
      }}
    >
      {isActive ? <FullscreenOffIcon /> : <FullscreenOnIcon />}
    </Box>
  );
};
