import { useModal } from 'hooks/useModal';
import { ModalContent } from '../../GlobalModal';
import { Box, Button, Typography } from '@mui/material';
import { COLORS } from '../../../../constants';
import { CloseModalIcon } from '../../CloseModalIcon';
import { useTranslation } from 'react-i18next';

export const AllVideosAnalyzedModal = () => {
  const { t } = useTranslation();
  const { closeModal, modalData } = useModal();

  const submitModal = () => {
    closeModal();
    modalData?.callback?.();
  };

  return (
    <ModalContent>
      <CloseModalIcon />
      <Typography sx={{ mt: 2, color: COLORS.WHITE, fontSize: '1.25rem', fontWeight: '500' }}>
        {t('modal.allVideosAnalyzedModal.title')}
      </Typography>
      <Typography sx={{ mt: 4, color: COLORS.WHITE, whiteSpace: 'pre-line' }}>
        {t('modal.allVideosAnalyzedModal.description')}
      </Typography>
      <Box sx={{ mt: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={closeModal}
          sx={{ minWidth: '210px', maxHeight: '50px' }}
        >
          {t('modal.allVideosAnalyzedModal.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{ minWidth: '210px', maxHeight: '50px' }}
          onClick={submitModal}
        >
          {t('modal.allVideosAnalyzedModal.submit')}
        </Button>
      </Box>
    </ModalContent>
  );
};
