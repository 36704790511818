import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { PlayPauseButton } from '../PlayPauseButton';
import React, { useEffect } from 'react';
import { useVideoPlayerContext, VideoStateProps } from '../../../contexts/VideoPlayerContext';
import { SeekBackwardIcon } from 'assets/icons/SeekBackwardIcon';
import { SeekForwardIcon } from 'assets/icons/SeekForwardIcon';
import { COLORS } from '../../../constants';
import { Timestamps } from 'components/Timeline/Timestamps';
import { Slider } from 'components/Slider';
import { InteractiveTimeline } from 'components/Timeline/InteractiveTimeline';
import { useTranslation } from 'react-i18next';
import { formatCalendarDate } from 'components/calendar/utils';
import { selectedLanguage } from 'index';
import { formatTime } from 'components/Timeline/utils';
import { GeneralSection } from './GeneralSection';
import { useFileParams } from 'hooks/useFileParams';
import { MarkerReviewInfo } from 'components/MarkerReviewInfo';
import { MarkersControls } from './MarkerControls';
import { useGetFilesForDay } from 'hooks/useGetFilesForDay';
import { REVIEW_STATUS } from 'contexts/PatientListProvider';

const SKIP_SECONDS = 10;

export const Controls = () => {
  const { t } = useTranslation();
  const {
    videoState: { playing },
    videoPlayerRef,
    setVideoState,
    fileCreatedAt,
  } = useVideoPlayerContext();
  const duration = videoPlayerRef.current ? videoPlayerRef.current.getDuration() : 0;

  const { selectedFileId } = useFileParams();

  const { filesForDay } = useGetFilesForDay();
  const file = filesForDay.find((file) => file.fileId === selectedFileId);

  const totalFilesDuration = filesForDay
    .filter(({ fileReviewStatus }) => fileReviewStatus === file?.fileReviewStatus)
    .reduce((acc, file) => acc + file.lengthInSeconds, 0);

  const [lowerBound, setLowerBound] = React.useState(0);
  const [upperBound, setUpperBound] = React.useState(duration);

  const playPauseHandler = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      playing: !playing,
    }));
  };

  const handleSeekForward = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + SKIP_SECONDS);
    }
  };

  const handleSeekBackward = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - SKIP_SECONDS);
    }
  };

  useEffect(() => {
    setUpperBound(duration);
  }, [duration]);

  return (
    <Stack
      width={'100%'}
      sx={{
        px: '24px',
        pb: '12px',
        pt: '18px',
        bgcolor: COLORS.BLUE_SECOND,
        borderRadius: '0 0 8px 8px',
      }}
    >
      <Stack justifyContent="space-between" direction="row" alignItems="center" mb="14px">
        <Typography variant="h3">
          {fileCreatedAt && formatCalendarDate(fileCreatedAt, selectedLanguage)}{' '}
          {!!totalFilesDuration && file && (
            <Typography component={'span'} variant="P1_R" ml="4px">
              ({t('patient.sessionLength')}{' '}
              {formatTime(file?.lengthInSeconds, file?.lengthInSeconds)})
            </Typography>
          )}
        </Typography>
        <MarkerReviewInfo />
      </Stack>

      <Stack spacing={4}>
        <Stack spacing={1} mt={2} direction={'row'}>
          <Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'} mx={0.5}>
              <SeekButton onClick={handleSeekBackward} Icon={SeekBackwardIcon} />
              <Box
                sx={{
                  height: '24px',
                  width: '24px',
                }}
              >
                <PlayPauseButton
                  isPlaying={playing}
                  size="small"
                  handlePlayPause={playPauseHandler}
                />
              </Box>
              <SeekButton onClick={handleSeekForward} Icon={SeekForwardIcon} />
            </Stack>
          </Stack>
          <Stack flex={1} pl={2}>
            {file && (
              <InteractiveTimeline
                startTime={lowerBound}
                file={file}
                totalDuration={upperBound - lowerBound}
                fileDuration={upperBound - lowerBound}
              />
            )}
            {duration && (
              <Slider
                onLowerBoundsUpdate={setLowerBound}
                onUpperBoundsUpdate={setUpperBound}
                lowerBound={0}
                upperBound={duration}
              />
            )}
            <Timestamps totalFilesDuration={duration} />
          </Stack>
        </Stack>

        {file && (
          <MarkersControls
            markers={file.markers}
            fileId={selectedFileId}
            fileDuration={duration}
            disabled={file.fileReviewStatus === REVIEW_STATUS.REVIEWED}
          />
        )}

        <GeneralSection
          duration={duration}
          currentDateFiles={filesForDay}
          totalFilesDuration={totalFilesDuration}
        />
      </Stack>
    </Stack>
  );
};

interface SeekButtonProps {
  onClick: () => void;
  Icon: () => JSX.Element;
}

const SeekButton = ({ Icon, onClick }: SeekButtonProps) => {
  const { t } = useTranslation();

  return (
    <Box position={'relative'} sx={{ height: '24px', width: '24px' }}>
      <SvgIcon
        viewBox="0 0 18 19"
        onClick={onClick}
        sx={{ cursor: 'pointer', position: 'relative' }}
      >
        <Icon />
      </SvgIcon>
      <Typography
        variant="P5_R"
        sx={{
          color: COLORS.BLUE_BORDER,
          position: 'absolute',
          width: 50,
          bottom: -20,
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        {t('patient.tenSeconds')}
      </Typography>
    </Box>
  );
};
