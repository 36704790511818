import { Box, Button, styled } from '@mui/material';
import { ArrowLeft } from '../../assets/icons/ArrowLeft';
import { ArrowRight } from '../../assets/icons/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../constants';
import { useGetFilesForDay } from 'hooks/useGetFilesForDay';
import { getVideoPageLink } from 'components/calendar/utils';
import { useFileParams } from 'hooks/useFileParams';

export const PatientReviewArrows = () => {
  const navigate = useNavigate();
  const { patientId, selectedFileId } = useFileParams();
  const { filesForDay } = useGetFilesForDay();
  const currentFileIndex = filesForDay.findIndex((file) => file.fileId === selectedFileId);

  const isPreviousFileAvailable = currentFileIndex > 0;
  const isNextFileAvailable = currentFileIndex !== filesForDay.length - 1;

  const goToPreviousFile = () => {
    if (!isPreviousFileAvailable) return;
    const link = getVideoPageLink(filesForDay[currentFileIndex - 1], patientId);
    navigate(link);
  };

  const goToNextFile = () => {
    if (!isNextFileAvailable) return;
    const link = getVideoPageLink(filesForDay[currentFileIndex + 1], patientId);
    navigate(link);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <ArrowButtonStyled
        variant="contained"
        size="medium"
        color="primary"
        disabled={!isPreviousFileAvailable}
        onClick={goToPreviousFile}
      >
        <ArrowLeft />
      </ArrowButtonStyled>
      <ArrowButtonStyled
        variant="contained"
        size="medium"
        color="primary"
        disabled={!isNextFileAvailable}
        onClick={goToNextFile}
      >
        <ArrowRight />
      </ArrowButtonStyled>
    </Box>
  );
};

export const ArrowButtonStyled = styled(Button)(({ disabled }) => ({
  padding: '12px 24px',
  background: COLORS.PRIMARY,
  boxShadow: 'none',
  svg: {
    width: '24px',
    height: '24px',
  },
  'svg path': {
    fill: disabled ? '#2121214d' : COLORS.BLACK_PRIMARY,
  },
}));
