import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

export const AuthLayout = () => {
  return (
    <Container disableGutters maxWidth={false}>
      <Outlet />
    </Container>
  );
};
