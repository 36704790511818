import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { AuthLayout } from './components/layouts/AuthLayout';
import { Login } from './pages/Login';
import { Layout } from './components/layouts/Layout';
import { Settings } from './pages/Settings';
import { Dashboard } from './pages/Dashboard';
import { LayoutSidebar } from './components/layouts/LayoutSidebar';
import { Patient } from './pages/Patient';
import { NotFound } from './pages/NotFound';
import { Register } from 'pages/Register';
import { Calendar } from 'pages/Calendar';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/register" element={<AuthLayout />}>
        <Route index element={<Register />} />
      </Route>
      <Route path="/settings" element={<Layout />}>
        <Route index element={<Settings />} />
      </Route>
      <Route path="/dashboard" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard/patient" element={<LayoutSidebar />}>
          <Route path="/dashboard/patient/:patientId" element={<Calendar />} />
          <Route
            path="/dashboard/patient/:patientId/:date/file/:selectedFileId"
            element={<Patient />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
