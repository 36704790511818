import { Collapse, IconButton, Stack, Typography, Link, Box } from '@mui/material';
import { COLORS } from '../../constants';
import { FC, useState } from 'react';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import { Timeline } from 'components/Timeline';
import { useTranslation } from 'react-i18next';
import { Timestamps } from 'components/Timeline/Timestamps';
import { useNavigate, useParams } from 'react-router-dom';
import { getVideoPageLink } from './utils';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';
import { formatDuration } from 'utils/dateUtils';

interface DayPreviewProps {
  date: string;
  files: PatientFileRO[];
  totalFilesDuration: number;
}

export const DayPreview: FC<DayPreviewProps> = ({ date, files, totalFilesDuration }) => {
  const [isExpanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const params = useParams<{ patientId: string }>();
  const { t } = useTranslation();
  const handleNavigateToDay = () => {
    navigate(getVideoPageLink(files[0], params.patientId as string));
  };

  return (
    <Box onClick={handleNavigateToDay} sx={{ cursor: 'pointer' }}>
      <Stack bgcolor={COLORS.BLUE_SECOND} gap="10px" p="12px 18px 24px 18px" borderRadius="8px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{date}</Typography>

          <Stack direction="row" alignItems="center" gap="4px">
            <Typography variant="P3_SB">{t('calendar.allVideos')}</Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!isExpanded);
              }}
              sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
            >
              <ArrowDownIcon />
            </IconButton>
          </Stack>
        </Stack>

        <Collapse in={isExpanded}>
          <Stack>
            {files.map((file, index) => (
              <Stack
                sx={{
                  'span > span': { color: COLORS.GRAY_PRIMARY },
                  '&:hover': { bgcolor: COLORS.WHITE },
                }}
                key={index}
                direction="row"
                justifyContent="space-between"
                p="3px 8px"
                borderRadius="2px"
              >
                <Link
                  onClick={(e) => e.stopPropagation()}
                  variant="P5_R"
                  href={getVideoPageLink(file, params.patientId as string)}
                  color={COLORS.GRAY_PRIMARY}
                >
                  {`#${index + 1} Video`}
                </Link>
                <Typography variant="P5_R">
                  <span>{t('calendar.duration')}: </span>
                  {formatDuration(file.lengthInSeconds)}
                </Typography>
                <Typography variant="P5_R">
                  <span>{t('calendar.mlDetected')}: </span>
                  {file.markers.filter((marker) => marker.type === 'ML_CREATED').length}
                </Typography>
                <Typography variant="P5_R">
                  <span>{t('calendar.detectedByReviewer')}: </span>
                  {
                    file.markers.filter(
                      (marker) =>
                        marker.doctorReview?.reviewResult === REVIEW_RESULT_STATUS.DETECTED
                    ).length
                  }
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Collapse>
        <Stack p="0 10px">
          <Timeline files={files} totalFilesDuration={totalFilesDuration} />
          <Timestamps totalFilesDuration={totalFilesDuration} />
        </Stack>
      </Stack>
    </Box>
  );
};
