import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useCreateMarker } from 'api/queries/markers/useCreateMarker';
import { useDeleteMarker } from 'api/queries/markers/useDeleteMarker';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { ArrowLeft } from 'assets/icons/ArrowLeft';
import { ArrowRight } from 'assets/icons/ArrowRight';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { COLORS } from '../../../constants';
import { useVideoPlayerContext } from 'contexts/VideoPlayerContext';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createMarkerParam } from 'components/PatientReview';
import styled from '@emotion/styled';

interface MarkersControlsProps {
  markers: FileMarker[];
  fileId: string;
  fileDuration: number;
  disabled: boolean;
}

export const MarkersControls: FC<MarkersControlsProps> = ({
  markers,
  fileId,
  fileDuration,
  disabled,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get('markerId') && markers[0]) {
      // Replace is necessary because otherwise we trigger the useEffect on the back button press (which re-adds the markerId to the URL)
      // see issue https://motus-med.atlassian.net/jira/software/projects/MM/boards/1?selectedIssue=MM-299
      navigate({ search: createMarkerParam(markers[0].id) }, { state: 'force', replace: true });
    }
  }, [searchParams, markers, navigate]);

  const activeMarker = useMemo(() => {
    const id = searchParams.get('markerId') ?? markers[0]?.id;
    const index = markers.findIndex((marker) => marker.id === id);
    return { id, index };
  }, [searchParams, markers]);

  const areMarksAvailable = markers.length > 0;

  const TIME_OFFSET = 10;

  const { t } = useTranslation();
  const { videoState, videoPlayerRef } = useVideoPlayerContext();

  const { mutate: createMarkerMutation, isPending: isCreatingMarker } = useCreateMarker();
  const { mutate: deleteMarkerMutation, isPending: isDeletingMarker } = useDeleteMarker();

  const playedSeconds = Math.floor(videoState.playedSeconds);

  const sortedMarkers = useMemo(
    () => [...markers].sort((a, b) => a.startSecond - b.startSecond),
    [markers]
  );

  const isPreviousMarkerAvailable = activeMarker.index > 0;
  const isNextMarkerAvailable = sortedMarkers.length - 1 > activeMarker.index;

  const goToPreviousMarker = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(sortedMarkers[activeMarker.index - 1].startSecond);
      navigate({
        search: createMarkerParam(sortedMarkers[activeMarker.index - 1].id),
      });
    }
  };

  const goToNextMarker = () => {
    if (videoPlayerRef.current) {
      const isBeforeFirstMarker = sortedMarkers[0].startSecond > playedSeconds;
      const navigationTime = isBeforeFirstMarker
        ? sortedMarkers[0].startSecond
        : sortedMarkers[activeMarker.index + 1].startSecond;
      videoPlayerRef.current.seekTo(navigationTime);
      navigate({
        search: createMarkerParam(sortedMarkers[activeMarker.index - 1].id),
      });
    }
  };

  const createMarker = () => {
    const startSecond = playedSeconds > TIME_OFFSET ? playedSeconds - TIME_OFFSET : 0;
    const endSecond =
      playedSeconds + TIME_OFFSET > fileDuration ? fileDuration : playedSeconds + TIME_OFFSET;

    createMarkerMutation({
      fileId,
      data: { startSecond: Math.floor(startSecond), endSecond: Math.floor(endSecond) },
    });
  };

  const isCreateMarkerAvailable = sortedMarkers.every((marker) => {
    return (
      playedSeconds < marker.startSecond - TIME_OFFSET ||
      playedSeconds > (marker.endSecond || fileDuration) + TIME_OFFSET
    );
  });

  const deleteMarker = () => {
    deleteMarkerMutation(
      { fileId, markerId: activeMarker.id },
      {
        onSuccess: () => {
          const getMarkerId = () => {
            if (markers[0]?.id === activeMarker.id) {
              if (markers.length > 1) {
                return markers[1]?.id;
              } else {
                return null;
              }
            }
            return markers[0]?.id;
          };
          const markerId = getMarkerId();

          navigate({ search: markerId ? createMarkerParam(markerId) : '' }, { state: 'force' });
        },
      }
    );
  };

  return (
    <Stack direction="row" gap={2} justifyContent="space-between">
      <Box>
        {areMarksAvailable ? (
          <MarkerButton>
            <IconButton disabled={!isPreviousMarkerAvailable} onClick={goToPreviousMarker}>
              <ArrowLeft />
            </IconButton>
            <Typography variant="P1_R" sx={{ mx: 1 }}>
              {`Mark #${activeMarker.index + 1}`}
            </Typography>
            <IconButton disabled={!isNextMarkerAvailable} onClick={goToNextMarker}>
              <ArrowRight />
            </IconButton>
          </MarkerButton>
        ) : (
          <MarkerButton
            sx={{
              opacity: 0.5,
            }}
          >
            <Typography variant="P1_R" sx={{ mx: 1 }}>
              {t('markers.noMarks')}
            </Typography>
          </MarkerButton>
        )}
      </Box>

      {!disabled && (
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={createMarker}
            disabled={isCreatingMarker || !isCreateMarkerAvailable}
          >
            {t('patient.addNewMarker')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={deleteMarker}
            disabled={isDeletingMarker || !sortedMarkers[activeMarker.index]}
            sx={{
              padding: '10px',
            }}
          >
            <TrashIcon />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const MarkerButton = styled(Box)(() => ({
  display: 'flex',
  height: '50px',
  padding: '10px 36px',
  borderRadius: '200px',
  background: COLORS.PRIMARY,
  alignItems: 'center',
  mr: 'auto',
}));
