import { useMutation, useQueryClient } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '../index';

export const useDeleteMarker = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMarker,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
    },
  });
};

const deleteMarker = async ({
  fileId,
  markerId,
}: {
  fileId: string;
  markerId: string;
}): Promise<void> => {
  return baseClient({
    url: `/markers/${markerId}`,
    method: 'DELETE',
  });
};
