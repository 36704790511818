import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import { LogoSmall } from '../../assets/LogoSmall';
import { NAVBAR_HEIGHT } from './constants';
import { PlusIcon } from '../../assets/icons/PlusIcon';
import { ProfileButton } from '../ProfileButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLastDashboardFiltersUrl } from '../../utils';
import { MODAL_TYPE, useModal } from '../../hooks/useModal';

export const Navbar = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, zIndex: 1000, position: 'fixed', width: '100%', top: 0 }}>
      <AppBar position="static" sx={{ height: NAVBAR_HEIGHT }} elevation={0}>
        <Toolbar sx={{ ml: 7, mr: 7, height: '100%' }}>
          <Box
            onClick={() => navigate(getLastDashboardFiltersUrl())}
            sx={{ height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <LogoSmall />
          </Box>
          <Box sx={{ flexGrow: 1, mr: 2 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              size="small"
              sx={{ mr: 3, maxHeight: '50px' }}
              onClick={() => openModal(MODAL_TYPE.PARENT_INVITATION)}
              endIcon={<PlusIcon />}
            >
              {t('navbar.invitePatient')}
            </Button>
            <ProfileButton />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
