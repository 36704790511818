import { Box, Typography, Alert, TextField, Stack, Tooltip, SvgIcon, Button } from '@mui/material';
import { useSignIn } from 'api/queries/auth/useSignIn';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { AxiosError } from 'axios';
import { DEFAULT_URL, COLORS } from '../../constants';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid, isValidEmail } from 'utils';
import { useSignUp } from 'api/queries/auth/useSignUp';
import { PropsWithChildren } from 'react';

interface FormInputs {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  invitationCode: string;
}

interface RegistrationFormProps {
  invitationCode: string;
}

export const RegistrationForm = ({ invitationCode }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const signUp = useSignUp();
  const signIn = useSignIn();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      invitationCode,
    },
  });

  const ERRORS = {
    INVITATION_CODE_NOT_FOUND: {
      field: 'invitationCode',
      error: { type: 'custom', message: t('error.INVITATION_CODE_NOT_FOUND') },
    },
    INVITATION_CODE_ALREADY_USED: {
      field: 'invitationCode',
      error: { type: 'custom', message: t('error.INVITATION_CODE_ALREADY_USED') },
    },
    EMAIL_ALREADY_TAKEN: {
      field: 'email',
      error: { type: 'custom', message: t('error.EMAIL_ALREADY_TAKEN') },
    },
  };

  const handleEmailValidation = (email: string) => {
    return isValidEmail(email) || t('register.validation.email');
  };

  const handlePasswordValidation = (password: string) => {
    return isPasswordValid(password) || t('register.validation.password');
  };

  const getError = (errorCode: string) => {
    const error = ERRORS[errorCode as keyof typeof ERRORS];

    if (!error) {
      return { field: 'email', error: { type: 'custom', message: t('error.UNKNOWN_ERROR') } };
    }

    return error;
  };

  const handleSignUp = async (data: FormInputs) => {
    const { email, password, firstName, lastName, invitationCode } = data;

    signUp.mutate(
      {
        email,
        password,
        firstName,
        lastName,
        invitationCode,
      },
      {
        onError: (error) => {
          if (error instanceof AxiosError) {
            const errCode = error?.response?.data?.code;
            const formError = getError(errCode);
            setError(formError.field as keyof FormInputs, formError.error);
          }
        },
        onSuccess: async () => {
          await signIn.mutateAsync({
            username: email,
            password,
          });
          navigate(DEFAULT_URL.DASHBOARD);
        },
      }
    );
  };

  const errorsList = [errors.email?.message, errors.password?.message].filter(Boolean) as string[];

  return (
    <Box component={'form'} onSubmit={handleSubmit(handleSignUp)}>
      <Typography
        variant="body2"
        sx={{
          color: COLORS.BLACK_PRIMARY,
          mb: 2,
          ml: 2,
        }}
      >
        {t('register.fillDetails')}
      </Typography>
      {(errors.email || errors.password) && (
        <ErrorAlertList>
          {errorsList.map((error) => (
            <ErrorItem message={error} />
          ))}
        </ErrorAlertList>
      )}
      <Typography
        sx={{
          color: COLORS.BLACK_SECOND,
          fontSize: '0.875rem',
        }}
      >
        {t('common.email')}
      </Typography>
      <TextField
        sx={{ mt: 0.6, mb: 2 }}
        required
        fullWidth
        id="email"
        placeholder={t('register.placeholders.email')}
        autoFocus
        error={!!errors?.email}
        {...register('email', {
          required: true,
          validate: handleEmailValidation,
        })}
      />
      <Typography
        sx={{
          color: COLORS.BLACK_SECOND,
          fontSize: '0.875rem',
        }}
      >
        {t('common.firstName')}
      </Typography>
      <TextField
        sx={{ mt: 0.6, mb: 2 }}
        required
        fullWidth
        id="first-name"
        placeholder={t('register.placeholders.firstName')}
        {...register('firstName', { required: true })}
      />
      <Typography
        sx={{
          color: COLORS.BLACK_SECOND,
          fontSize: '0.875rem',
        }}
      >
        {t('common.lastName')}
      </Typography>
      <TextField
        sx={{ mt: 0.6, mb: 2 }}
        required
        fullWidth
        id="last-name"
        placeholder={t('register.placeholders.lastName')}
        {...register('lastName', { required: true })}
      />
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography
          sx={{
            color: COLORS.BLACK_SECOND,
            fontSize: '0.875rem',
          }}
        >
          {t('common.password')}
        </Typography>
        <Tooltip title={t('register.passwordTooltip')}>
          <SvgIcon>
            <InfoIcon />
          </SvgIcon>
        </Tooltip>
      </Stack>
      <TextField
        sx={{ mt: 0.6, mb: 2 }}
        required
        fullWidth
        type="password"
        id="password"
        placeholder={'••••••'}
        {...register('password', {
          required: true,
          validate: handlePasswordValidation,
        })}
        error={!!errors?.password}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        sx={{
          my: 2,
        }}
        size="large"
        disabled={signIn.isPending || signUp.isPending}
      >
        {t('common.register')}
      </Button>
    </Box>
  );
};

const ErrorAlertList: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Alert
      severity="error"
      icon={false}
      sx={{
        my: 2,
        background: COLORS.BLACK_PRIMARY,
        color: '#fff',
        borderRadius: '8px',
      }}
    >
      <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
        {t('register.correctErrors')}:
      </Typography>
      <Box
        component={'ul'}
        sx={{
          margin: 0,
          paddingInlineStart: 2,
        }}
      >
        {children}
      </Box>
    </Alert>
  );
};

interface ErrorItemProps {
  message: string;
}

const ErrorItem = ({ message }: ErrorItemProps) => {
  return (
    <Box
      component={'li'}
      sx={{
        '::marker': {
          fontSize: '12px',
        },
      }}
    >
      {message}
    </Box>
  );
};
