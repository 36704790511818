import { keyCloakClient } from '../client/keycloakClient';
import { getCookie, setAuthTokens } from './utils';

type RefreshRO = {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_expires_in: number;
  refresh_token: string;
};

export const refreshToken = async () => {
  const { access_token, refresh_token, refresh_expires_in, expires_in, id_token }: RefreshRO =
    await (
      await keyCloakClient
    )({
      url: '/realms/motus/protocol/openid-connect/token?scope=openid',
      method: 'POST',
      data: {
        client_id: 'motus-app',
        grant_type: 'refresh_token',
        scope: 'openid',
        refresh_token: getCookie('refreshToken'),
      },
    });
  setAuthTokens(access_token, expires_in, refresh_token, refresh_expires_in, id_token);
};
