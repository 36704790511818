import { SyntheticEvent, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { COLORS } from '../../constants';
import { PlayerIcon } from '../../assets/icons/PlayerIcon';
import { findDOMNode } from 'react-dom';
import { FullscreenControls } from './FullscreenControls';
import { FullscreenButton } from './FullscreenButton';
import screenfull from 'screenfull';
import { alpha, CircularProgress, styled, Typography } from '@mui/material';
import { useVideoPlayerContext, VideoStateProps } from '../../contexts/VideoPlayerContext';
import { Video } from './Video';
import { formatTime } from './utilts';
import { useTranslation } from 'react-i18next';

export const VideoPlayer = () => {
  const { videoPlayerRef, videoState, setVideoState, fileUrl, thumbnailUrl } =
    useVideoPlayerContext();
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  const { playing, isFullscreen, buffer } = videoState;

  const currentTime = videoPlayerRef?.current?.getCurrentTime() || 0;

  const seekHandler = (e: SyntheticEvent<Element, Event> | Event) => {
    const target = e.target as HTMLInputElement;
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      played: parseFloat(target.value) / 100,
    }));
  };

  const playPauseHandler = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      playing: !videoState.playing,
    }));
  };

  const pauseHandler = () => {
    if (playing) {
      setVideoState((prevVideoState: VideoStateProps) => ({
        ...prevVideoState,
        playing: false,
      }));
    }
  };

  const seekMouseUpHandler = (e: SyntheticEvent | Event, value: number | number[]) => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      seeking: false,
    }));
    videoPlayerRef?.current?.seekTo(((!Array.isArray(value) && value) || 0) / 100);
  };

  const onSeekMouseDownHandler = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      seeking: true,
    }));
  };

  const handleToggleFullscreen = () => {
    if (screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      // @ts-ignore
      screenfull.request(findDOMNode(wrapperRef.current));
    }
  };

  const handleFullscreenChange = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      isFullscreen: screenfull.isFullscreen,
    }));
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box
      key={`box-${fileUrl}`}
      sx={{
        position: 'relative',
        width: '100%',
        height: 'max(30vw, 350px)',
      }}
      ref={wrapperRef}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '8px 8px 0 0',
        }}
        onClick={pauseHandler}
      >
        <PosterBoxStyled
          sx={{
            backgroundImage: `url(${thumbnailUrl})`,
          }}
        />
        {buffer && (
          <LoaderBoxStyled>
            <CircularProgress />
          </LoaderBoxStyled>
        )}
        {!playing && !buffer && (
          <PlayIconBoxStyled onClick={playPauseHandler}>
            <PlayerIcon />
          </PlayIconBoxStyled>
        )}
        {!isFullscreen && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '18px',
              right: '18px',
              zIndex: 100,
              'svg path': {
                fill: COLORS.WHITE,
              },
            }}
          >
            <FullscreenButton
              isActive={isFullscreen}
              handleToggleFullscreen={handleToggleFullscreen}
            />
          </Box>
        )}
        {!isFullscreen && (
          <TimePointer>
            <Typography
              sx={{
                color: COLORS.GRAY_PRIMARY,
                span: {
                  color: COLORS.BLACK_PRIMARY,
                },
              }}
            >
              {t('patient.timePointer')}:{' '}
              <Typography component={'span'} sx={{ color: COLORS.BLACK_PRIMARY }}>
                {formatTime(currentTime)}
              </Typography>
            </Typography>
          </TimePointer>
        )}
        <Video />
        {isFullscreen && (
          <FullscreenControls
            playPauseHandler={playPauseHandler}
            seekHandler={seekHandler}
            seekMouseUpHandler={seekMouseUpHandler}
            onSeekMouseDownHandler={onSeekMouseDownHandler}
            isFullscreenActive={isFullscreen}
            handleToggleFullscreen={handleToggleFullscreen}
          />
        )}
      </Box>
    </Box>
  );
};

const TimePointer = styled(Box)(() => ({
  position: 'absolute',
  bottom: '18px',
  left: '18px',
  zIndex: 100,
  backgroundColor: alpha(COLORS.WHITE, 0.9),
  padding: '8px 16px',
  borderRadius: '24px',
}));

const PosterBoxStyled = styled(Box)(() => ({
  borderRadius: '8px 8px 0 0',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  filter: 'blur(7px)',
  zIndex: -1,
  overflow: 'hidden',
  backgroundSize: 'cover',
  '&:before': {
    borderRadius: '8px 8px 0 0',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: COLORS.BLACK_PRIMARY,
    opacity: 0.01,
  },
}));

const LoaderBoxStyled = styled(Box)(() => ({
  position: 'absolute',
  height: '68px',
  width: '68px',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2000,
}));

const PlayIconBoxStyled = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  height: '68px',
  width: '68px',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '4px',
  zIndex: 100,
  svg: {
    height: '68px',
    width: '68px',
  },
}));
