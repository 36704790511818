import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SummaryFormInputs } from './CalendarSummaryDialog';

interface CalendarSummaryFormProps {
  form: UseFormReturn<SummaryFormInputs>;
}

export const CUSTOM_FIELD_VALUE = 'custom';

export const CalendarSummaryForm: FC<CalendarSummaryFormProps> = ({ form }) => {
  const { t } = useTranslation();
  const { control, watch } = form;

  const [description, recommendations] = watch(['description', 'recommendations']);

  // TODO: Replace with real presets
  const descriptionPresents = [
    { value: CUSTOM_FIELD_VALUE, label: t('calendar.summary.customComment') },
    { value: '1', label: 'Test description 1' },
    { value: '2', label: 'Test description 2' },
  ];

  const recommendationsPresents = [
    { value: CUSTOM_FIELD_VALUE, label: t('calendar.summary.customRecomendation') },
    { value: '1', label: 'Test recommendation 1' },
    { value: '2', label: 'Test recommendation 2' },
  ];

  return (
    <>
      <Stack gap="8px" mb="24px">
        <Typography variant="label">{t(`calendar.summary.results`)}</Typography>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Select {...field} size="small" displayEmpty>
              <MenuItem value="" disabled>
                {t('calendar.summary.commentPlaceholder')}
              </MenuItem>
              {descriptionPresents.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {description === CUSTOM_FIELD_VALUE && (
          <Controller
            control={control}
            name="customDescription"
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                multiline
                minRows={3}
                sx={{ '.MuiInputBase-root': { height: 'auto' } }}
              />
            )}
          />
        )}
      </Stack>

      <Stack gap="8px">
        <Typography variant="label">{t('calendar.summary.recommendations')}</Typography>
        <Controller
          control={control}
          name="recommendations"
          render={({ field }) => (
            <Select {...field} size="small" displayEmpty>
              <MenuItem value="" disabled>
                {t('calendar.summary.recommendationsPlaceholder')}
              </MenuItem>
              {recommendationsPresents.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {recommendations === CUSTOM_FIELD_VALUE && (
          <Controller
            control={control}
            name="customRecommendation"
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                multiline
                minRows={3}
                sx={{ '.MuiInputBase-root': { height: 'auto' } }}
              />
            )}
          />
        )}
      </Stack>

      <FormControlLabel
        control={
          <Controller
            control={control}
            name="sendToML"
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
        }
        label={
          <Typography variant="label" mb={0}>
            {t('calendar.summary.sentToML')}
          </Typography>
        }
      />
    </>
  );
};
