export const InfoIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0996 15.999C12.0996 16.5513 12.5473 16.999 13.0996 16.999C13.6519 16.999 14.0996 16.5513 14.0996 15.999V11.999C14.0996 11.4467 13.6519 10.999 13.0996 10.999C12.5473 10.999 12.0996 11.4467 12.0996 11.999V15.999ZM13.0996 8.99902C13.3829 8.99902 13.6204 8.90319 13.8121 8.71152C14.0038 8.51986 14.0996 8.28236 14.0996 7.99902C14.0996 7.71569 14.0038 7.47819 13.8121 7.28652C13.6204 7.09486 13.3829 6.99902 13.0996 6.99902C12.8163 6.99902 12.5788 7.09486 12.3871 7.28652C12.1954 7.47819 12.0996 7.71569 12.0996 7.99902C12.0996 8.28236 12.1954 8.51986 12.3871 8.71152C12.5788 8.90319 12.8163 8.99902 13.0996 8.99902ZM13.0996 21.999C11.7163 21.999 10.4163 21.7365 9.19961 21.2115C7.98294 20.6865 6.92461 19.974 6.02461 19.074C5.12461 18.174 4.41211 17.1157 3.88711 15.899C3.36211 14.6824 3.09961 13.3824 3.09961 11.999C3.09961 10.6157 3.36211 9.31569 3.88711 8.09902C4.41211 6.88236 5.12461 5.82402 6.02461 4.92402C6.92461 4.02402 7.98294 3.31152 9.19961 2.78652C10.4163 2.26152 11.7163 1.99902 13.0996 1.99902C14.4829 1.99902 15.7829 2.26152 16.9996 2.78652C18.2163 3.31152 19.2746 4.02402 20.1746 4.92402C21.0746 5.82402 21.7871 6.88236 22.3121 8.09902C22.8371 9.31569 23.0996 10.6157 23.0996 11.999C23.0996 13.3824 22.8371 14.6824 22.3121 15.899C21.7871 17.1157 21.0746 18.174 20.1746 19.074C19.2746 19.974 18.2163 20.6865 16.9996 21.2115C15.7829 21.7365 14.4829 21.999 13.0996 21.999ZM13.0996 19.999C15.3329 19.999 17.2246 19.224 18.7746 17.674C20.3246 16.124 21.0996 14.2324 21.0996 11.999C21.0996 9.76569 20.3246 7.87402 18.7746 6.32402C17.2246 4.77402 15.3329 3.99902 13.0996 3.99902C10.8663 3.99902 8.97461 4.77402 7.42461 6.32402C5.87461 7.87402 5.09961 9.76569 5.09961 11.999C5.09961 14.2324 5.87461 16.124 7.42461 17.674C8.97461 19.224 10.8663 19.999 13.0996 19.999Z"
        fill="#404040"
      />
    </svg>
  );
};
