import { usePatientFiles } from 'api/queries/patient/usePatientFiles';
import { REVIEW_STATUS } from 'contexts/PatientListProvider';
import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useGetFilesForDay = () => {
  const { patientId, selectedFileId, date } = useParams();

  const { data, isLoading } = usePatientFiles({ patientId: patientId || '' });

  const filesForDay = useMemo(() => {
    const currentFile = data?.files.find((file) => file.fileId === selectedFileId);
    const isReviewed = currentFile?.fileReviewStatus === REVIEW_STATUS.REVIEWED;

    return (
      data?.files.filter((file) => {
        const isDateFromQueryParams = isSameDay(
          new Date(file.fileUploadedAt),
          new Date(date as string)
        );
        const isCorrectStatus = isReviewed
          ? file.fileReviewStatus === REVIEW_STATUS.REVIEWED
          : file.fileReviewStatus === REVIEW_STATUS.AWAITING_REVIEW;
        return isDateFromQueryParams && isCorrectStatus;
      }) ?? []
    );
  }, [date, selectedFileId, data]);

  return { filesForDay, isLoading };
};
