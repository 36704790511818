import {
  DEFAULT_LANGUAGE,
  DEFAULT_URL,
  LOCALSTORAGE_FILTER_ROUTE_KEY,
  supportedLanguages,
} from './constants';
import dayjs from 'dayjs';
import { selectedLanguage } from './index';

export const getDefaultLanguage = () => {
  const USER_BROWSER_LANGUAGE = navigator.language.slice(0, 2);

  if (supportedLanguages.includes(USER_BROWSER_LANGUAGE)) {
    return USER_BROWSER_LANGUAGE;
  }

  return DEFAULT_LANGUAGE;
};

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const isPasswordValid = (password: string) => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&(),.?":{}|<>]).{6,}$/.test(password);
};

export const getLastDashboardFiltersUrl = (): string => {
  return localStorage.getItem(LOCALSTORAGE_FILTER_ROUTE_KEY) || DEFAULT_URL.DASHBOARD;
};

export const setLastDashboardFiltersUrl = (pathname: string, search: string) => {
  localStorage.setItem(LOCALSTORAGE_FILTER_ROUTE_KEY, pathname + search);
};

export const getDateInLocaleFormat = (date: string) =>
  dayjs(date).locale(selectedLanguage).format('L LT');
