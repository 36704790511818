export const ArrowRight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4345 5.43451C11.7469 5.12209 12.2535 5.12209 12.5659 5.43451L18.5659 11.4345C18.7159 11.5845 18.8002 11.788 18.8002 12.0002C18.8002 12.2124 18.7159 12.4159 18.5659 12.5659L12.5659 18.5659C12.2535 18.8783 11.7469 18.8783 11.4345 18.5659C11.1221 18.2535 11.1221 17.7469 11.4345 17.4345L16.0688 12.8002H4.0002C3.55837 12.8002 3.2002 12.442 3.2002 12.0002C3.2002 11.5584 3.55837 11.2002 4.0002 11.2002H16.0688L11.4345 6.56588C11.1221 6.25346 11.1221 5.74693 11.4345 5.43451Z"
      fill="currentColor"
    />
  </svg>
);
