import { Box } from '@mui/material';
import { COLORS } from '../../constants';

interface SeekerProps {
  position: `${number}%`;
}

export const Seeker = ({ position }: SeekerProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        height: '22px',
        width: '3px',
        bgcolor: COLORS.BLUE_BORDER,
        borderRadius: '2px',
        top: '-3px',
        left: position,
        zIndex: 101,
      }}
    />
  );
};
