import { Button, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/queries';
import { useChangeEmail } from 'api/queries/auth/useChangeEmail';
import { authSignIn } from 'api/queries/auth/useSignIn';
import { AxiosError } from 'axios';
import { Modal, ModalFooter } from 'components/Modal';
import { useCurrentUserContext } from 'contexts/SessionProvider';
import { useDialogState } from 'hooks/useDialogState';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface ChangeEmailModalProps {
  dialogState: ReturnType<typeof useDialogState>;
  newEmail: string;
}

export const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ dialogState, newEmail }) => {
  const [password, setPassword] = useState('');
  const { email } = useCurrentUserContext();
  const { mutate } = useChangeEmail();

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const submitNewEmail = () => {
    mutate(newEmail, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SESSION] });
        toast.success(t('settings.successful'));
        dialogState.close();
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const errCode = err?.response?.data?.code;
          toast.error(errCode ? t(`error.${errCode}`) : err.message);
        }
      },
    });
  };

  const validatePassword = async () => {
    try {
      await authSignIn({
        username: email,
        password,
      });

      submitNewEmail();
    } catch (err) {
      if (err instanceof AxiosError) {
        const message = err?.response?.data?.error_description;
        toast.error(message);
      }
    }
  };

  return (
    <Modal title={t('settings.changeEmail')} dialogState={dialogState}>
      <Stack flex={1}>
        <Stack gap="8px" maxWidth="330px">
          <Typography variant="label">{t(`login.password`)}</Typography>
          <TextField
            id={'common'}
            size="small"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack>
      </Stack>
      <ModalFooter>
        <Button variant="outlined" color="secondary" onClick={dialogState.close}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!password}
          onClick={validatePassword}
        >
          {t('settings.saveChanges')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
