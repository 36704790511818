import { usePatientFiles } from 'api/queries/patient/usePatientFiles';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const useMarkerDetails = () => {
  const { patientId, selectedFileId } = useParams();
  const [searchParams] = useSearchParams();
  const { data: files } = usePatientFiles({ patientId: patientId || '' });
  const markerId = searchParams.get('markerId');

  const marker = useMemo(() => {
    const fileMarkers = files?.files.find((file) => file.fileId === selectedFileId);

    const activeMarkerIndex = fileMarkers?.markers.findIndex((marker) => marker.id === markerId);
    const isValidMarker = !!fileMarkers && activeMarkerIndex !== -1;

    if (!fileMarkers || !isValidMarker || activeMarkerIndex === undefined) {
      return {
        activeMarker: null,
        previousMarker: null,
        nextMarker: null,
      };
    }

    return {
      activeMarker: fileMarkers.markers[activeMarkerIndex],
      previousMarker: fileMarkers.markers[activeMarkerIndex - 1] || null,
      nextMarker: fileMarkers.markers[activeMarkerIndex + 1] || null,
    };
  }, [files, markerId, selectedFileId]);

  return marker;
};
