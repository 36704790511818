import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { dateToQueryParam } from 'utils/dateUtils';

export const formatCalendarDate = (date: string, locale: string) => {
  const dateObject = new Date(date);

  return dateObject.toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
  });
};

export const getVideoPageLink = (video: PatientFileRO, patientId: string) => {
  return `/dashboard/patient/${patientId}/${dateToQueryParam(video.fileUploadedAt)}/file/${
    video.fileId
  }`;
};
