import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { Alert, AlertColor } from '@mui/material';

export const Toast = () => {
  return (
    <Toaster
      position="bottom-right"
      reverseOrder
      containerClassName="toast"
      toastOptions={{ duration: 6000 }}
    >
      {(t) => {
        return (
          <ToastBar
            toast={t}
            style={{
              margin: '1px 0px',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {({ message }) => {
              return (
                <Alert severity={t.type as AlertColor} onClose={() => toast.dismiss()}>
                  {message}
                </Alert>
              );
            }}
          </ToastBar>
        );
      }}
    </Toaster>
  );
};
