import { Box } from '@mui/material';
import { useVideoPlayerContext } from '../../../contexts/VideoPlayerContext';
import { formatTime } from '../utilts';

export const VideoPlayerTime = () => {
  const { isPlayerReady, videoPlayerRef } = useVideoPlayerContext();

  const currentTime = videoPlayerRef.current ? videoPlayerRef.current.getCurrentTime() : 0;
  const duration = videoPlayerRef.current ? videoPlayerRef.current.getDuration() : 0;

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  return <Box>{isPlayerReady ? `${formatCurrentTime} / ${formatDuration}` : '0:00 / 0:00'}</Box>;
};
