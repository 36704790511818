import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';
import { FileMarker } from './usePatientFiles';
import { REVIEW_RESULT_STATUS } from './constants';

// TODO: There are 2 very similar types, they need to be tweaked after Kuba's back from vacation
// Checking the swagger it seems like the type is war smaller than here (i did it based on some pull request some time ago)
// For example im not getting the firstname/lastname here anymore
export type PatientFileRO = {
  patientId: string;
  firstName: string;
  lastName: string;
  parentFirstName: string;
  parentLastName: string;
  caseName: string;
  parentEmail: string;
  fileName: string;
  fileCreatedAt: string;
  fileUploadedAt: string;
  fileUrl: string;
  reviewResult: REVIEW_RESULT_STATUS | null;
  seizureType: string | null;
  reviewDescription: string | null;
  reviewCreatedAt: string | null;
  thumbnailUrl: string;
  encryptionKey: string;
  encryptionKeyMD5: string;
  lengthInSeconds: number;
  markers: FileMarker[];
};

type useGetPatientFileProps = {
  patientId: string;
  fileId: string;
};

export const useGetPatientFile = (props: useGetPatientFileProps) =>
  useQuery({
    queryKey: [QUERY_KEYS.PATIENT_FILES, props],
    queryFn: () => verification(props),
  });

const verification = async ({
  patientId,
  fileId,
}: useGetPatientFileProps): Promise<PatientFileRO> => {
  return baseClient({
    url: `/patients/${patientId}/files/${fileId}`,
    method: 'GET',
  });
};
