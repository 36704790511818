import { Stack } from '@mui/material';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { COLORS } from '../../constants';
import { FC } from 'react';
import { calculateOverlapPercentage } from './utils';

interface FileMarkerProps {
  marker: FileMarker;
  fileDuration: number;
  startTime: number;
}

const MINIMAL_MARKER_WIDTH = 5.5;
export const MARKER_END_SECOND_OFFSET = 1; // 1 second offset for marker end time to cover the whole marker

export const Marker: FC<FileMarkerProps> = ({ marker, fileDuration, startTime }) => {
  const isGeneratedByML = marker.type === 'ML_CREATED';
  const isUserFeedback = marker.type === 'USER_FEEDBACK';

  const isVerifiedByDoctor = marker.doctorReview?.reviewType === 'DOCTOR';
  const isDetected =
    isVerifiedByDoctor && marker.doctorReview?.reviewResult === REVIEW_RESULT_STATUS.DETECTED;
  const isNotDetected =
    isVerifiedByDoctor && marker.doctorReview?.reviewResult === REVIEW_RESULT_STATUS.NOT_DETECTED;

  const overlap = calculateOverlapPercentage({
    timeline: {
      start: startTime,
      end: startTime + fileDuration,
    },
    point: {
      start: marker.startSecond,
      end: (marker.endSecond || fileDuration) + MARKER_END_SECOND_OFFSET,
    },
  });

  const getMarkerWidth = () => {
    if (overlap) {
      const percentage = Math.round(overlap.overlapEndPercentage - overlap.overlapStartPercentage);
      const markerWidth = `max(${Math.round(percentage)}%, ${MINIMAL_MARKER_WIDTH}px)`;
      return markerWidth;
    }
    return 0;
  };

  const leftOffset = overlap ? Math.round(overlap.overlapStartPercentage) + '%' : undefined;

  const getResultLabelColor = () => {
    switch (true) {
      case !isVerifiedByDoctor:
        return '';
      case isNotDetected:
        return COLORS.GREEN_TERTIARY;
      case isDetected:
        return COLORS.RED_TERTIARY;
      default:
        return '';
    }
  };

  return (
    <Stack
      height="18px"
      border={`1px solid ${COLORS.SECONDARY}`}
      bgcolor={COLORS.BLUE_PRIMARY}
      borderRadius="1px"
      width={getMarkerWidth()}
      position="absolute"
      left={leftOffset}
      top="-1px"
    >
      <Stack bgcolor={isUserFeedback ? COLORS.SECONDARY : ''} flex={1} />
      <Stack bgcolor={getResultLabelColor()} flex={1} />
      <Stack bgcolor={isGeneratedByML ? COLORS.YELLOW_PRIMARY : ''} flex={1} />
    </Stack>
  );
};
