import {
  FOCAL,
  FOCAL_AWARE,
  FOCAL_MOTOR,
  FOCAL_NONMOTOR,
  GENERALIZED,
  GENERALIZED_MOTOR,
  GENERALIZED_NONMOTOR,
  SEIZURE,
  UNKNOWN,
  UNKNOWN_MOTOR,
  UNKNOWN_NONMOTOR,
} from 'api/queries/patient/constants';

const ENUM_SPACER = '_';

export const joinSeizureEnum = (seizureArray: string[]) => {
  return seizureArray.filter(Boolean).join(ENUM_SPACER);
};
export const splitSeizureEnum = (seizureString: string) => {
  return seizureString.split(ENUM_SPACER);
};

const enumParts = [
  ...Object.values(SEIZURE),
  ...Object.values(FOCAL),
  ...Object.values(FOCAL_AWARE),
  ...Object.values(FOCAL_MOTOR),
  ...Object.values(FOCAL_NONMOTOR),
  ...Object.values(GENERALIZED),
  ...Object.values(GENERALIZED_MOTOR),
  ...Object.values(GENERALIZED_NONMOTOR),
  ...Object.values(UNKNOWN),
  ...Object.values(UNKNOWN_MOTOR),
  ...Object.values(UNKNOWN_NONMOTOR),
];

export const parseSeizureEnum = (seizureString: string) => {
  const enums: string[] = [];
  let seizure = seizureString;

  const parseSingleEnum = () => {
    for (const part of enumParts) {
      if (seizure.startsWith(part)) {
        enums.push(part);
        seizure = seizure.slice(part.length + 1);
        break;
      }
    }

    if (seizure) {
      parseSingleEnum();
    }
  };

  parseSingleEnum();

  return enums;
};
