import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

interface ValidateCodePayload {
  code: string;
}

export const CODE_LENGTH = 6;

export const useValidateCode = () => {
  return useMutation({
    mutationFn: validateCode,
  });
};

const validateCode = (data: ValidateCodePayload) => {
  return baseClient({
    url: '/invitation/doctor/validate',
    method: 'POST',
    data,
  });
};
