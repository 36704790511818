import { AxiosError } from 'axios';

export const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: false,
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        window.location.href = '/';
      } else {
        console.error('react-query error', error);
      }
    },
  },
};
