export const BackIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        id="Vector"
        d="M18.7073 11.7081L14.4136 16.0006L18.7073 20.2931C18.8002 20.386 18.8739 20.4963 18.9242 20.6177C18.9745 20.7391 19.0004 20.8692 19.0004 21.0006C19.0004 21.132 18.9745 21.2621 18.9242 21.3835C18.8739 21.5048 18.8002 21.6151 18.7073 21.7081C18.6144 21.801 18.5041 21.8747 18.3827 21.9249C18.2613 21.9752 18.1312 22.0011 17.9998 22.0011C17.8684 22.0011 17.7383 21.9752 17.6169 21.9249C17.4955 21.8747 17.3852 21.801 17.2923 21.7081L12.2923 16.7081C12.1993 16.6152 12.1256 16.5049 12.0753 16.3835C12.0249 16.2621 11.999 16.132 11.999 16.0006C11.999 15.8691 12.0249 15.739 12.0753 15.6176C12.1256 15.4962 12.1993 15.3859 12.2923 15.2931L17.2923 10.2931C17.3852 10.2001 17.4955 10.1264 17.6169 10.0762C17.7383 10.0259 17.8684 10 17.9998 10C18.1312 10 18.2613 10.0259 18.3827 10.0762C18.5041 10.1264 18.6144 10.2001 18.7073 10.2931C18.8002 10.386 18.8739 10.4963 18.9242 10.6177C18.9745 10.7391 19.0004 10.8692 19.0004 11.0006C19.0004 11.132 18.9745 11.2621 18.9242 11.3835C18.8739 11.5048 18.8002 11.6151 18.7073 11.7081Z"
        fill="#034986"
      />
    </g>
  </svg>
);
