import { format } from 'date-fns';

export const calculateOverlapPercentage = ({
  timeline,
  point,
}: {
  timeline: { start: number; end: number };
  point: { start: number; end: number };
}) => {
  const overlapStart = Math.max(timeline.start, point.start);
  const overlapEnd = Math.min(timeline.end, point.end);
  const overlapLength = overlapEnd - overlapStart;
  const timelineLength = timeline.end - timeline.start;
  const overlapStartPercentage = ((overlapStart - timeline.start) / timelineLength) * 100;
  const overlapEndPercentage = ((overlapEnd - timeline.start) / timelineLength) * 100;

  if (overlapLength <= 0) {
    return false;
  }

  return {
    overlapStartPercentage,
    overlapEndPercentage,
  };
};

export const getStepSize = (totalFilesDuration: number) => {
  const breakpoints = [5, 10, 30, 1 * 60, 5 * 60, 10 * 60, 30 * 60, 60 * 60]; // in seconds
  const STEPS_LIMIT = 7;

  let stepSize = 0;
  let numberOfSteps = 0;

  for (const breakpoint of breakpoints) {
    const numberOfStepsForBreakpoint = Math.round(totalFilesDuration / breakpoint);

    if (STEPS_LIMIT >= numberOfStepsForBreakpoint) {
      stepSize = breakpoint;
      numberOfSteps = numberOfStepsForBreakpoint;
      break;
    }
  }

  return { stepSize, numberOfSteps };
};

export const formatTime = (seconds: number, totalFilesDuration: number) => {
  const isLongerThanHour = totalFilesDuration / 60 / 60 > 1;
  const date = new Date();

  // The date is returned with the current timezone (+01:00), so we need to reset it to 00:00
  // otherwise the timestamp starts from 1 hour
  date.setHours(0, 0, seconds);

  if (isLongerThanHour) {
    return format(date, 'HH:mm') + 'h';
  }

  return format(date, 'mm:ss') + 'm';
};
