import { Box, CircularProgress } from '@mui/material';
import { SessionRO, useSession } from 'api/queries/auth/useSession';
import { createContext, ReactNode, useContext } from 'react';

type SessionProviderProps = {
  children: ReactNode;
};

const CurrentUserContext = createContext<SessionRO | null | undefined>(null);

export const useCurrentUserContext = () => {
  const currentUser = useContext(CurrentUserContext);

  if (!currentUser) {
    throw new Error('CurrentUserContext: No value provided');
  }

  return currentUser;
};

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const { data: sessionData } = useSession();

  return (
    <CurrentUserContext.Provider value={sessionData}>
      {sessionData ? (
        children
      ) : (
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </CurrentUserContext.Provider>
  );
};
