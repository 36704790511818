export const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Search">
      <path
        id="Vector"
        d="M19.5342 20.725L15.7816 16.9621C15.1715 17.4917 14.46 17.9041 13.6473 18.1994C12.8346 18.4948 11.9697 18.6424 11.0528 18.6424C8.85292 18.6424 6.99108 17.8786 5.46731 16.3511C3.94353 14.8235 3.18164 12.9802 3.18164 10.8212C3.18164 8.66223 3.94435 6.81895 5.46978 5.29137C6.9952 3.76379 8.84096 3 11.0071 3C13.1732 3 15.0138 3.76379 16.5291 5.29137C18.0444 6.81895 18.802 8.66376 18.802 10.8258C18.802 11.6986 18.6596 12.5423 18.3749 13.357C18.0901 14.1717 17.663 14.9355 17.0935 15.6484L20.9071 19.4113C21.0901 19.5833 21.1816 19.7943 21.1816 20.0444C21.1816 20.2945 21.0808 20.5205 20.8792 20.7224C20.6944 20.9075 20.4675 21 20.1986 21C19.9297 21 19.7082 20.9083 19.5342 20.725ZM11.0223 16.8093C12.6749 16.8093 14.0795 16.2238 15.2363 15.0526C16.3931 13.8815 16.9715 12.471 16.9715 10.8212C16.9715 9.17143 16.3931 7.76096 15.2363 6.58982C14.0795 5.41867 12.6749 4.8331 11.0223 4.8331C9.35283 4.8331 7.93376 5.41867 6.76511 6.58982C5.59647 7.76096 5.01215 9.17143 5.01215 10.8212C5.01215 12.471 5.59647 13.8815 6.76511 15.0526C7.93376 16.2238 9.35283 16.8093 11.0223 16.8093Z"
        fill="#212121"
      />
    </g>
  </svg>
);
