export const UnitedKingdomFlagIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1412_14495)">
      <path
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.15355 3.12891C1.52514 3.9465 1.0513 4.88884 0.775391 5.91256H4.9372L2.15355 3.12891Z"
        fill="#0052B4"
      />
      <path
        d="M16.2242 5.91253C15.9483 4.88884 15.4745 3.9465 14.8461 3.12891L12.0625 5.91253H16.2242Z"
        fill="#0052B4"
      />
      <path
        d="M0.775391 10.0859C1.05133 11.1096 1.52517 12.052 2.15355 12.8695L4.93711 10.0859H0.775391Z"
        fill="#0052B4"
      />
      <path
        d="M13.3705 1.65358C12.5529 1.02517 11.6106 0.551328 10.5869 0.275391V4.43717L13.3705 1.65358Z"
        fill="#0052B4"
      />
      <path
        d="M3.62939 14.3461C4.44699 14.9745 5.38933 15.4483 6.41302 15.7242V11.5625L3.62939 14.3461Z"
        fill="#0052B4"
      />
      <path
        d="M6.41299 0.275391C5.3893 0.551328 4.44696 1.02517 3.62939 1.65355L6.41299 4.43714V0.275391Z"
        fill="#0052B4"
      />
      <path
        d="M10.5869 15.7242C11.6106 15.4483 12.5529 14.9745 13.3705 14.3461L10.5869 11.5625V15.7242Z"
        fill="#0052B4"
      />
      <path
        d="M12.0625 10.0859L14.8461 12.8696C15.4745 12.052 15.9483 11.1096 16.2242 10.0859H12.0625Z"
        fill="#0052B4"
      />
      <path
        d="M16.4323 6.95653H9.54353H9.5435V0.0677188C9.20191 0.02325 8.85366 0 8.5 0C8.14628 0 7.79809 0.02325 7.45653 0.0677188V6.95647V6.9565H0.567719C0.52325 7.29809 0.5 7.64634 0.5 8C0.5 8.35372 0.52325 8.70191 0.567719 9.04347H7.45647H7.4565V15.9323C7.79809 15.9768 8.14628 16 8.5 16C8.85366 16 9.20191 15.9768 9.54347 15.9323V9.04353V9.0435H16.4323C16.4768 8.70191 16.5 8.35372 16.5 8C16.5 7.64634 16.4768 7.29809 16.4323 6.95653Z"
        fill="#D80027"
      />
      <path
        d="M10.5869 10.0879L14.1568 13.6578C14.321 13.4937 14.4776 13.3221 14.627 13.1442L11.5707 10.0879H10.5869V10.0879Z"
        fill="#D80027"
      />
      <path
        d="M6.41317 10.0879H6.41311L2.84326 13.6577C3.00739 13.8219 3.17898 13.9785 3.35686 14.128L6.41317 11.0716V10.0879Z"
        fill="#D80027"
      />
      <path
        d="M6.41317 5.91372V5.91366L2.8433 2.34375C2.67911 2.50787 2.52248 2.67947 2.37305 2.85734L5.42939 5.91369H6.41317V5.91372Z"
        fill="#D80027"
      />
      <path
        d="M10.5869 5.9132L14.1568 2.34327C13.9927 2.17908 13.8211 2.02245 13.6432 1.87305L10.5869 4.92939V5.9132Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1412_14495">
        <rect x="0.5" width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
