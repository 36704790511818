import { useParams } from 'react-router-dom';

export const useFileParams = () => {
  const params = useParams<{
    patientId: string;
    date: string;
    selectedFileId: string;
  }>();

  return {
    patientId: params.patientId as string,
    date: params.date as string,
    selectedFileId: params.selectedFileId as string,
  };
};
