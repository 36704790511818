import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

export const useChangeEmail = () =>
  useMutation({
    mutationFn: changeEmail,
  });

const changeEmail = async (email: string): Promise<void> => {
  return baseClient({
    url: `/doctors/account-email`,
    method: 'PUT',
    data: { email },
  });
};
