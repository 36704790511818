import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';

export type InvitationCodeRO = {
  code: string;
};

export const useGetInvitationCode = () =>
  useQuery({
    queryKey: [QUERY_KEYS.GET_CODE],
    queryFn: () => getCode(),
  });

const getCode = async (): Promise<InvitationCodeRO> => {
  return baseClient({
    url: `/invitation/parent/generate`,
    method: 'GET',
  });
};
