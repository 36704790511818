import { Box } from '@mui/material';
import { useModal } from '../../../hooks/useModal';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { COLORS } from '../../../constants';

export const CloseModalIcon = () => {
  const { closeModal } = useModal();

  return (
    <Box
      onClick={closeModal}
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        top: '48px',
        right: '48px',
        width: '20px',
        height: '20px',
        svg: {
          width: '20px',
          height: '20px',
        },
        'svg path': {
          fill: COLORS.WHITE,
        },
      }}
    >
      <CloseIcon />
    </Box>
  );
};
