import { Box, Typography, Alert, Button } from '@mui/material';
import { useValidateCode, CODE_LENGTH } from 'api/queries/auth/useValidateCode';
import CodeInput from 'components/CodeInput';
import { COLORS } from '../../constants';
import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface CodeEnterFormProps {
  onConfirm: (code: string) => void;
}

export const CodeEnterForm = ({ onConfirm }: CodeEnterFormProps) => {
  const { t } = useTranslation();

  const { mutate, isPending } = useValidateCode();

  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState('');

  const isCodeValid = code.length === CODE_LENGTH;

  const handleCodeChange = (newCode: string) => {
    setCode(newCode);
    if (isError) {
      setIsError(false);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    mutate(
      { code },
      {
        onError: () => setIsError(true),
        onSuccess: () => {
          onConfirm(code);
        },
      }
    );
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Typography
        variant="body2"
        sx={{
          color: COLORS.BLACK_PRIMARY,
          mb: 2,
        }}
      >
        {t('register.enterInvitationCode')}
      </Typography>
      {isError && (
        <Alert
          severity="error"
          icon={false}
          sx={{
            mb: 2,
            background: COLORS.BLACK_PRIMARY,
            color: '#fff',
            borderRadius: '8px',
          }}
        >
          <Typography variant="body2">{t('register.validation.code')}</Typography>
        </Alert>
      )}
      <CodeInput onChange={handleCodeChange} isError={isError} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        sx={{
          mt: 4,
          mb: 2,
        }}
        size="large"
        disabled={!isCodeValid || isError || isPending}
      >
        {t('register.confirmCode')}
      </Button>
    </Box>
  );
};
