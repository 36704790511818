export const SeekBackwardIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="-0.25"
        y="0.25"
        width="17.5"
        height="17.5"
        rx="8.75"
        transform="matrix(-1 0 0 1 17.5 0.787109)"
        fill="white"
      />
      <rect
        x="-0.25"
        y="0.25"
        width="17.5"
        height="17.5"
        rx="8.75"
        transform="matrix(-1 0 0 1 17.5 0.787109)"
        stroke="#034986"
        strokeWidth="0.5"
      />
      <path
        d="M4.9 7.85877V10.4301C4.9 10.5153 4.93161 10.5971 4.98787 10.6573C5.04413 10.7176 5.12043 10.7515 5.2 10.7515H7.6C7.65937 10.7515 7.71742 10.7327 7.76679 10.6974C7.81617 10.6621 7.85466 10.6119 7.87739 10.5531C7.90011 10.4943 7.90605 10.4297 7.89446 10.3673C7.88286 10.3049 7.85425 10.2476 7.81225 10.2027L6.83012 9.15084C7.29831 8.67552 7.88625 8.35761 8.52127 8.23642C9.15628 8.11522 9.81052 8.19607 10.4031 8.46895C10.9957 8.74183 11.5007 9.19478 11.8556 9.77181C12.2105 10.3488 12.3997 11.0247 12.4 11.7157C12.4 11.8009 12.4316 11.8827 12.4879 11.943C12.5441 12.0032 12.6204 12.0371 12.7 12.0371C12.7796 12.0371 12.8559 12.0032 12.9121 11.943C12.9684 11.8827 13 11.8009 13 11.7157C13 10.8973 12.7757 10.097 12.3549 9.414C11.9341 8.731 11.3354 8.19543 10.633 7.87374C9.93068 7.55205 9.15561 7.45842 8.40402 7.60446C7.65244 7.7505 6.95743 8.12979 6.40525 8.69524L5.41225 7.63137C5.37029 7.58637 5.31682 7.55572 5.25859 7.5433C5.20037 7.53088 5.14001 7.53724 5.08516 7.56159C5.03032 7.58594 4.98345 7.62717 4.95048 7.68007C4.91752 7.73298 4.89995 7.79517 4.9 7.85877Z"
        fill="#034986"
      />
    </svg>
  );
};
