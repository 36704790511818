import { VideoPlayer } from '../VideoPlayer';
import Box from '@mui/material/Box';
import { VideoPlayerProvider } from '../../contexts/VideoPlayerContext';
import { Controls } from 'components/VideoPlayer/Controls';

export const PatientVideo = () => {
  return (
    <Box
      sx={{
        width: '100%',
        pb: '24px',
        overflow: 'hidden',
      }}
    >
      <VideoPlayerProvider>
        <VideoPlayer />
        <Controls />
      </VideoPlayerProvider>
    </Box>
  );
};
