import { Box } from '@mui/material';
import { COLORS, SHADOWS } from '../../constants';
import dayjs from 'dayjs';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { selectedLanguage } from '../../index';

interface PatientCardProps {
  parentRef: MutableRefObject<HTMLUListElement | null>;
  fileId: string;
  isActive: boolean;
  fileUploadedAt: string;
  onClick: () => void;
  thumbnailUrl: string;
  isFirstLoad: boolean;
  setIsFirstLoad: (value: boolean) => void;
  handleScrollbarPosition: (value: number) => void;
}

export const PatientCard = ({
  parentRef,
  fileId,
  isActive,
  fileUploadedAt,
  onClick,
  thumbnailUrl,
  isFirstLoad,
  setIsFirstLoad,
  handleScrollbarPosition,
}: PatientCardProps) => {
  const ref = useRef<HTMLUListElement | null>(null);
  const { selectedFileId } = useParams();

  useEffect(() => {
    if (selectedFileId === fileId && parentRef?.current && ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      setIsFirstLoad(false);
      const scrollTop = rect.top - rect.height + 10;
      handleScrollbarPosition(scrollTop);
    }
  }, [selectedFileId, isFirstLoad, parentRef, setIsFirstLoad, fileId]);

  return (
    <Box
      ref={ref}
      data-id={fileId}
      onClick={onClick}
      sx={{
        mt: 1.5,
        maxWidth: 174,
        borderRadius: '12px',
        boxShadow: isActive ? SHADOWS.THIRD : null,
        border: isActive ? `2px solid ${COLORS.BLUE_BORDER}` : `2px solid ${COLORS.WHITE}`,
        background: COLORS.WHITE,
        cursor: 'pointer',
      }}
    >
      <Box sx={{ height: 100, borderRadius: '12px' }}>
        <img
          height="100"
          width="100%"
          src={thumbnailUrl}
          alt="patient"
          style={{ objectFit: 'cover', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
        />
      </Box>
      <Box sx={{ px: 1.5, py: 1, fontSize: '0.75rem', color: COLORS.BLUE_THIRD }}>
        {dayjs(fileUploadedAt).locale(selectedLanguage).format('DD.MM.YYYY HH:MM')}
      </Box>
    </Box>
  );
};
