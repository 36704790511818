import { Box, Grid, Link, Typography } from '@mui/material';
import { BACKGROUND, COLORS } from '../constants';
import { LogoBlack } from '../assets/LogoBlack';
import { useState } from 'react';
import { RegistrationForm } from 'components/RegistrationForm';
import { CodeEnterForm } from 'components/CodeInputForm';
import { Trans } from 'react-i18next';

export const Register = () => {
  const [invitationCode, setInvitationCode] = useState('');

  const hasCode = !!invitationCode;

  const handleConfirm = (invitationCode: string) => {
    setInvitationCode(invitationCode);
  };

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        background: BACKGROUND.LOGIN,
        p: 2,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          minHeight: '100vh',
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box width={336}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LogoBlack />
          </Box>
          <Box sx={{ mt: 6 }}>
            {hasCode === false && <CodeEnterForm onConfirm={handleConfirm} />}
            {hasCode && <RegistrationForm invitationCode={invitationCode} />}
            <Typography textAlign={'center'} variant="body2">
              <Trans
                i18nKey={'common.orLogin'}
                components={{
                  Link: <Link href={'/'} sx={{ color: COLORS.GREEN_SECOND }} />,
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
