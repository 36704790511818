import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { SummaryHistory } from './SummaryHistory';
import { useDialogState } from 'hooks/useDialogState';
import { Summary } from 'api/queries/summary/useGetSummaries';
import { DayPreview } from './DayPreview';
import { groupByDate } from 'pages/Calendar';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';

interface CaseSectionProps {
  summary: Summary;
  patientFiles: PatientFileRO[];
  index: number;
}

export const CaseSection: FC<CaseSectionProps> = ({ summary, index, patientFiles }) => {
  const dialogState = useDialogState();

  const files = summary.fileIds
    .map((fileId) => patientFiles.find((file) => file.fileId === fileId))
    .filter(Boolean) as PatientFileRO[];
  const groupedFiles = groupByDate(files);

  return (
    <Stack gap="8px" key={summary.createdAt}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{`Case ${index}`}</Typography>
        <SummaryHistory dayDetails={groupedFiles} dialogState={dialogState} summary={summary} />
      </Stack>

      {groupedFiles.map(({ date, files, totalDuration }) => (
        <DayPreview key={date} date={date} files={files} totalFilesDuration={totalDuration} />
      ))}
    </Stack>
  );
};
