import { useMutation, useQueryClient } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '..';

export interface SummaryBody {
  description: string;
  recommendations: string;
  sendMlDataToParent: boolean;
  fileIds: string[];
}

export const useCreateSummary = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSummary,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SUMMARIES] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENTS] });
    },
  });
};

const createSummary = async ({ patientId, ...data }: SummaryBody & { patientId: string }) => {
  return baseClient({
    url: `/patients/${patientId}/summaries`,
    method: 'POST',
    data,
  });
};
