import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  styled,
  TextareaAutosize,
  TextareaAutosizeProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { COLORS } from '../../constants';
import { useTranslation } from 'react-i18next';
import {
  FOCAL_AWARE,
  FOCAL,
  GENERALIZED,
  MotorType,
  REVIEW_RESULT_STATUS,
  REVIEW_SEIZURE_TYPE_LABELS,
  SEIZURE,
  SeizureType,
  UNKNOWN,
} from '../../api/queries/patient/constants';
import { PatientReviewArrows } from '../PatientReviewArrows';
import { Controller, useForm } from 'react-hook-form';
import { useReview } from '../../api/queries/markers/useReview';
import {
  createSearchParams,
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MODAL_TYPE } from '../../contexts/ModalContext';
import { useModal } from '../../hooks/useModal';
import { joinSeizureEnum, parseSeizureEnum } from 'utils/seizureEnum';
import { getVideoPageLink } from 'components/calendar/utils';
import { useGetFilesForDay } from 'hooks/useGetFilesForDay';

type UnknownField = 'UNKNOWN';
type FormInputs = {
  error: string;
  description: string;
  focalAware: FOCAL_AWARE | UnknownField | '';
  seizureOnset: SEIZURE | UnknownField | '';
  motorType: MotorType | UnknownField | '';
  reviewResult: REVIEW_RESULT_STATUS | '';
  seizureType: SeizureType | UnknownField | '';
  reviewResultJoined: '';
};

const UNKNOWN_FIELD = 'UNKNOWN';

export const createMarkerParam = (id: string) => {
  return createSearchParams({
    markerId: id,
  }).toString();
};

const defaultValues = {
  error: '',
  description: '',
  focalAware: '',
  seizureOnset: '',
  motorType: '',
  reviewResult: '',
  seizureType: '',
} as FormInputs;

export const PatientReview = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, selectedFileId } = useParams();
  const { filesForDay } = useGetFilesForDay();

  const [searchParams] = useSearchParams();
  const activeMarkerId = searchParams.get('markerId');

  const activeMarker = useMemo(() => {
    return filesForDay
      .find((file) => file.fileId === selectedFileId)
      ?.markers.find((marker) => marker.id === activeMarkerId);
  }, [activeMarkerId, filesForDay, selectedFileId]);
  const isUserFeedback = activeMarker?.type === 'USER_FEEDBACK';

  const review = useReview({ isNew: !Boolean(activeMarker?.doctorReview) });

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    control,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors, isLoading, isSubmitting, isDirty },
  } = useForm<FormInputs>({
    defaultValues,
  });

  useEffect(() => {
    if (activeMarker?.doctorReview) {
      const isDetected = activeMarker.doctorReview.reviewResult === REVIEW_RESULT_STATUS.DETECTED;
      const enums = parseSeizureEnum(activeMarker.doctorReview.seizureType ?? '');
      const [seizureOnsetEnum, focalAwareEnum, motorTypeEnum, seizureTypeEnum] = enums;

      const seizureOnset = isDetected ? seizureOnsetEnum : '';
      const focalAware = isDetected ? focalAwareEnum : '';
      const motorType = isDetected ? motorTypeEnum : '';
      const seizureType = isDetected ? seizureTypeEnum : '';

      reset({
        error: '',
        description: activeMarker.doctorReview.description ?? '',
        focalAware: focalAware as any,
        seizureOnset: seizureOnset as any,
        motorType: motorType as any,
        reviewResult: activeMarker.doctorReview.reviewResult ?? '',
        seizureType: seizureType as any,
      });
    } else if (activeMarker) {
      reset(defaultValues);
    }
  }, [activeMarker, reset]);

  const onSubmit = async ({
    reviewResult,
    seizureType,
    description,
    focalAware,
    motorType,
    seizureOnset,
  }: FormInputs) => {
    try {
      clearErrors();

      if (!patientId || !selectedFileId || !reviewResult || !activeMarkerId) return;

      const result = joinSeizureEnum([seizureOnset, focalAware, motorType, seizureType]);

      await review.mutateAsync({
        markerId: activeMarkerId,
        reviewData: {
          reviewResult,
          seizureType: reviewResult === REVIEW_RESULT_STATUS.DETECTED ? result : null,
          description,
          patientId: patientId,
        },
      });

      reset(undefined, { keepValues: false, keepDirty: false, keepDefaultValues: false });

      const activeVideoIndex = filesForDay.findIndex((file) => file.fileId === selectedFileId);
      const activeMarkerIndex = filesForDay[activeVideoIndex].markers.findIndex(
        (file) => file.id === activeMarkerId
      );
      const nextNotReviewedMarkerForVideo = filesForDay[activeVideoIndex]?.markers.find(
        (marker, i) => !marker.doctorReview && i > activeMarkerIndex
      );

      const nextNotReviewedMarkerForDay = filesForDay[activeVideoIndex + 1]?.markers.find(
        (marker, i) => !marker.doctorReview
      );

      if (nextNotReviewedMarkerForVideo) {
        const link = getVideoPageLink(filesForDay[activeVideoIndex], patientId);
        const searchParams = createMarkerParam(nextNotReviewedMarkerForVideo.id);
        navigate({ pathname: link, search: searchParams }, { state: 'force' });
        return;
      }

      if (nextNotReviewedMarkerForDay) {
        const link = getVideoPageLink(filesForDay[activeVideoIndex + 1], patientId);
        const searchParams = createMarkerParam(nextNotReviewedMarkerForDay.id);
        navigate({ pathname: link, search: searchParams }, { state: 'force' });
        return;
      }

      const patientLink = `/dashboard/patient/${patientId}`;
      navigate(patientLink, { state: 'force' });

      // TODO: verify should we keep this
      // current logic is:
      // 1. if there is another not reviewed marker in the same video, navigate to it
      // 2. if there is another not reviewed marker in the next video, navigate to it
      // 3. if there is no more not reviewed markers, navigate to the patient page

      // if (awaitingMarkers?.length === 1) {
      //   const currenPatientIndex = patientList.findIndex((item) => item.patientId === patientId);
      //   const nextPatient =
      //     patientList[typeof currenPatientIndex === 'number' ? currenPatientIndex + 1 : 0];
      //   openModal(MODAL_TYPE.ALL_VIDEOS_ANALYZED, {
      //     callback: () => {
      //       navigate(
      //         `/dashboard/patient/${
      //           nextPatient ? nextPatient.patientId : patientList[0]?.patientId
      //         }`,
      //         { state: 'force' }
      //       );
      //     },
      //   });
      // } else {
      //   const currenVideoIndex = awaitingFiles?.findIndex(
      //     ({ fileId }) => fileId === selectedFileId
      //   );
      //   const nextVideo =
      //     awaitingFiles?.[typeof currenVideoIndex === 'number' ? currenVideoIndex + 1 : 0];
      //   const video = nextVideo ? nextVideo : videoFiles?.[0];

      //   if (!video) return;

      //   const link = getVideoPageLink(video, patientId);

      //   navigate(link, { state: 'force' });
      // }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errCode = err?.response?.data?.code;

        if (errCode === 'REVIEW_ALREADY_CREATED') {
          openModal(MODAL_TYPE.REVIEW_ALREADY_CREATED);
        } else {
          setError('error', {
            type: 'custom',
            message: errCode ? t(`error.${errCode}`) : err.message,
          });
        }
      }
    }
  };

  const isDetected = watch('reviewResult') === REVIEW_RESULT_STATUS.DETECTED;
  const seizureOnset = watch('seizureOnset');
  const motorType = watch('motorType');

  const getMotorTypeValues = (seizureOnset: SEIZURE | UnknownField | '') => {
    if (!seizureOnset) {
      return [];
    }

    return Object.keys(REVIEW_SEIZURE_TYPE_LABELS[seizureOnset]);
  };

  const getSeizureTypeValues = (
    seizureOnset: SEIZURE | UnknownField | '',
    motorType: MotorType | UnknownField | ''
  ) => {
    if (
      seizureOnset === SEIZURE.UNCLASSIFIED ||
      !seizureOnset ||
      !motorType ||
      motorType === UNKNOWN_FIELD
    ) {
      return [];
    }
    if (seizureOnset === SEIZURE.FOCAL) {
      return REVIEW_SEIZURE_TYPE_LABELS[seizureOnset][motorType as FOCAL];
    }

    if (seizureOnset === SEIZURE.GENERALIZED) {
      return REVIEW_SEIZURE_TYPE_LABELS[seizureOnset][motorType as GENERALIZED];
    }

    return REVIEW_SEIZURE_TYPE_LABELS[seizureOnset][motorType as UNKNOWN];
  };

  const motorTypeValues = getMotorTypeValues(seizureOnset);
  const seizureTypeValues = getSeizureTypeValues(seizureOnset, motorType);

  const displayFocalAwareValues = seizureOnset === SEIZURE.FOCAL;
  const displayMotorValues = !!motorTypeValues.length;
  const displaySeizureValues = !!seizureTypeValues.length;

  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }: any) => {
      if (
        !confirmedNavigation &&
        isDirty &&
        (currentLocation.pathname !== nextLocation.pathname ||
          currentLocation.search !== nextLocation.search) &&
        nextLocation.state !== 'force'
      ) {
        setLastLocation(nextLocation);
        openModal(MODAL_TYPE.CANCEL_REVIEW, {
          callback: () => {
            setConfirmedNavigation(true);
          },
        });
        return true;
      } else {
        return false;
      }
    },
    [isDirty]
  );
  let blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);
      blocker?.proceed?.();
      // @ts-ignore
      navigate({ pathname: lastLocation.pathname, search: lastLocation.search });
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  return (
    <Box sx={{ mb: 3 }}>
      <FormControl
        sx={{ width: '100%' }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        disabled={!activeMarker}
      >
        <Tooltip title={activeMarker ? '' : t('markers.tooltip')} followCursor>
          <Box
            sx={{
              background: COLORS.BLUE_SECOND,
              borderRadius: '8px',
              padding: '24px',
              ...(!activeMarker && {
                '> *': {
                  pointerEvents: 'none',
                },
                cursor: 'not-allowed',
              }),
            }}
          >
            {isUserFeedback && (
              <Typography variant="h5" sx={{ color: COLORS.BLACK_PRIMARY, mb: 3 }}>
                {t('patient.resultTitle')}
              </Typography>
            )}

            <Typography variant="label" id="review-result-group">
              {t('patient.videoReview')} ({t('common.mandatory').toLowerCase()})
            </Typography>
            <RadioGroup row aria-labelledby="review-result-group" sx={{ mt: 1 }}>
              {Object.values(REVIEW_RESULT_STATUS).map((reviewResult) => (
                <FormControlLabel
                  key={`${selectedFileId}${reviewResult}`}
                  {...register('reviewResult', {
                    required: true,
                    onChange: () => {
                      clearErrors();

                      resetField('seizureOnset');
                      resetField('focalAware');
                      resetField('motorType');
                      resetField('seizureType');
                    },
                  })}
                  checked={getValues('reviewResult') === reviewResult}
                  value={reviewResult}
                  control={<Radio color={errors.reviewResult ? 'error' : 'default'} />}
                  label={t(`reviewResult.${reviewResult}`)}
                  sx={{
                    ...(isDetected &&
                      reviewResult === REVIEW_RESULT_STATUS.DETECTED && {
                        background: COLORS.PRIMARY,
                      }),
                    mx: 0,
                    pr: '8px',
                    mr: '8px',
                    borderRadius: '4px 4px 0px 0px',
                  }}
                />
              ))}
            </RadioGroup>
            {isDetected && (
              <RadioGroup
                row
                aria-label={t('patient.ariaLabels.seizureOnset')}
                sx={{
                  mb: 2,
                  background: COLORS.PRIMARY,
                  borderRadius: '0px 4px 4px 4px',
                }}
              >
                {Object.keys(REVIEW_SEIZURE_TYPE_LABELS).map((seizureOnset) => {
                  return (
                    <FormControlLabel
                      key={`${selectedFileId}${seizureOnset}`}
                      {...register('seizureOnset', {
                        required: true,
                        onChange: (event) => {
                          clearErrors();

                          const displayMotorValues = !!getMotorTypeValues(getValues('seizureOnset'))
                            .length;
                          const displayFocalAwareValues =
                            getValues('seizureOnset') === SEIZURE.FOCAL;

                          if (displayMotorValues) {
                            setValue('motorType', UNKNOWN_FIELD);
                          } else {
                            resetField('motorType');
                          }
                          if (displayFocalAwareValues) {
                            setValue('focalAware', UNKNOWN_FIELD);
                          } else {
                            resetField('focalAware');
                          }
                          resetField('seizureType');
                        },
                        validate: {
                          required: () => {
                            if (!getValues('seizureOnset')) {
                              return 'invalid';
                            }
                            return true;
                          },
                        },
                      })}
                      checked={getValues('seizureOnset') === seizureOnset}
                      value={seizureOnset}
                      control={<Radio color={errors.seizureOnset ? 'error' : 'default'} />}
                      label={t(`reviewSeizure.${seizureOnset}`)}
                      sx={{
                        mx: 0,
                        pr: '8px',
                        mr: '8px',
                      }}
                    />
                  );
                })}
              </RadioGroup>
            )}
            {displayFocalAwareValues && (
              <FormControl
                sx={{
                  alignItems: 'flex-start',
                  mb: 2,
                  width: '100%',
                }}
              >
                <Typography variant="label" id="focal-awareness">
                  {t('patient.focalAwareness')} ({t('common.optional').toLowerCase()})
                </Typography>
                <Controller
                  control={control}
                  name="focalAware"
                  rules={{ required: true }}
                  defaultValue={UNKNOWN_FIELD}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      labelId="focal-awareness"
                      key={seizureOnset}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors();
                      }}
                    >
                      <MenuItem value={UNKNOWN_FIELD}>
                        <em>{t('common.unknown')}</em>
                      </MenuItem>
                      {Object.values(FOCAL_AWARE).map((focalAware) => {
                        return (
                          <MenuItem key={focalAware} value={focalAware}>
                            {t(`reviewSeizure.${focalAware}`)}{' '}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            )}

            {displayMotorValues && (
              <FormControl
                sx={{
                  alignItems: 'flex-start',
                  mb: 2,
                  width: '100%',
                }}
              >
                <Typography variant="label" id="motorType">
                  {t('patient.motorType')} ({t('common.optional').toLowerCase()})
                </Typography>
                <Controller
                  name="motorType"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={UNKNOWN_FIELD}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      labelId="motorType"
                      key={seizureOnset}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        const displaySeizureValues = !!getSeizureTypeValues(
                          getValues('seizureOnset'),
                          getValues('motorType')
                        ).length;

                        if (displaySeizureValues) {
                          setValue('seizureType', UNKNOWN_FIELD);
                        } else {
                          resetField('seizureType');
                        }

                        clearErrors();
                      }}
                    >
                      <MenuItem value={UNKNOWN_FIELD}>
                        <em>{t('common.unknown')}</em>
                      </MenuItem>
                      {motorTypeValues.map((motorType) => {
                        return (
                          <MenuItem key={motorType} value={motorType}>
                            {t(`reviewSeizure.${motorType}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            )}

            {displaySeizureValues && (
              <FormControl
                sx={{
                  alignItems: 'flex-start',
                  mb: 2,
                  width: '100%',
                }}
              >
                <Typography id="seizureType" variant="label">
                  {t('patient.seizureType')} ({t('common.optional').toLowerCase()})
                </Typography>
                <Controller
                  name="seizureType"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={UNKNOWN_FIELD}
                  key={seizureOnset + motorType}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      labelId="seizureType"
                      key={seizureOnset + motorType}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors();
                      }}
                    >
                      <MenuItem value={UNKNOWN_FIELD}>
                        <em>{t('common.unknown')}</em>
                      </MenuItem>
                      {seizureTypeValues.map((seizureType) => {
                        return (
                          <MenuItem key={seizureType} value={seizureType}>
                            {t(`reviewSeizure.${seizureType}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            )}
            <TextareaAutosizeStyled
              disabled={!activeMarker}
              key={`${selectedFileId}`}
              placeholder={t('patient.description.placeholder')}
              minRows="8"
              {...register('description', {
                required: false,
                onChange: () => clearErrors(),
              })}
            />
            {errors?.error && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Alert severity="error" sx={{ width: '100%', maxWidth: '600px' }}>
                  {errors?.error?.message as string}
                </Alert>
              </Box>
            )}
          </Box>
        </Tooltip>
        <Box sx={{ mt: '27px', gap: 2, display: 'flex', flexWrap: 'wrap' }}>
          <Stack flex={1} direction={'row'}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              sx={{ mr: 3 }}
              disabled={isLoading || isSubmitting || !activeMarkerId}
            >
              {t('patient.submit')}
            </Button>
            <PatientReviewArrows />
          </Stack>
        </Box>
      </FormControl>
    </Box>
  );
};

const TextareaAutosizeStyled = styled(TextareaAutosize)<TextareaAutosizeProps>(() => ({
  width: '100%',
  color: COLORS.BLUE_THIRD,
  marginTop: '6px',
  '&': {
    padding: '12px 16px',
    fontFamily: '"Baloo-2", sans-serif',
    borderWidth: '2px',
    borderColor: COLORS.PRIMARY,
    borderRadius: '8px',
    color: COLORS.BLUE_THIRD,
  },
}));
