import { FC } from 'react';
import { Modal, ModalFooter } from 'components/Modal';
import { useDialogState } from 'hooks/useDialogState';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants';
import { GroupedFiles } from 'pages/Calendar';
import { useForm } from 'react-hook-form';
import { DaySummary } from './DaySummary';
import { CUSTOM_FIELD_VALUE, CalendarSummaryForm } from './CalendarSummaryForm';
import toast from 'react-hot-toast';
import { SummaryBody, useCreateSummary } from 'api/queries/summary/useCreateSummary';

export interface SummaryFormInputs {
  description: string;
  customDescription: string;
  recommendations: string;
  customRecommendation: string;
  sendToML: boolean;
}

const defaultValues: SummaryFormInputs = {
  description: '',
  customDescription: '',
  recommendations: '',
  customRecommendation: '',
  sendToML: false,
};

interface CalendarSummaryDialogProps {
  dayDetails: GroupedFiles[];
  dialogState: ReturnType<typeof useDialogState>;
  patientId: string;
}

export const CalendarSummaryDialog: FC<CalendarSummaryDialogProps> = ({
  dayDetails,
  dialogState,
  patientId,
}) => {
  const { t } = useTranslation();
  const { mutate: createSummaryMutation, isPending } = useCreateSummary();

  const form = useForm<SummaryFormInputs>({
    defaultValues,
  });

  const { handleSubmit, watch } = form;

  const [description, recommendations] = watch(['description', 'recommendations']);

  const submitSummary = handleSubmit((data) => {
    const body: SummaryBody = {
      description:
        data.description === CUSTOM_FIELD_VALUE ? data.customDescription : data.description,
      recommendations:
        data.recommendations === CUSTOM_FIELD_VALUE
          ? data.customRecommendation
          : data.recommendations,
      sendMlDataToParent: data.sendToML,
      fileIds: dayDetails.flatMap((day) => day.files.map((file) => file.fileId)),
    };

    createSummaryMutation(
      { ...body, patientId },
      {
        onSuccess: () => {
          toast.success(t('calendar.summary.summarySubmitted'));
          dialogState.close();
        },
      }
    );
  });

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={dialogState.open}
        disabled={!dayDetails.length}
      >
        {t('calendar.summary.finishEvaluation')}
      </Button>

      <Modal title={t('calendar.summary.caseSummary')} dialogState={dialogState}>
        <Stack
          flex={1}
          mb="40px"
          bgcolor={COLORS.BLUE_SECOND}
          borderRadius="12px"
          p="12px 24px 24px 24px"
          overflow="auto"
          sx={{
            '.MuiTextField-root': {
              maxWidth: '720px',
              width: '100%',
              '.MuiInputBase-root': { bgcolor: COLORS.WHITE },
              fieldset: { border: 'unset' },
            },
          }}
        >
          <Stack>
            {dayDetails.map((day, index) => (
              <DaySummary key={day.date} dayDetails={day} defaultOpen={index === 0} />
            ))}
          </Stack>

          <Stack mt="16px" gap="32px">
            <CalendarSummaryForm form={form} />
          </Stack>
        </Stack>
        <ModalFooter>
          <Button variant="outlined" color="secondary" onClick={dialogState.close}>
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={submitSummary}
            disabled={!description || !recommendations || isPending}
          >
            {t('calendar.summary.submitButton')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
