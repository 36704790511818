import axios, { AxiosError, AxiosHeaders } from 'axios';
import { clearAuthTokens, getCookie } from '../auth/utils';
import { refreshToken } from '../auth/useRefreshToken';

type ApiParams = {
  baseURL: string;
};

const getApiInstance = ({ baseURL }: ApiParams) => {
  const defaultOptions = {
    baseURL,
  };

  const instance = axios.create(defaultOptions);

  const isTokenValid = () => {
    const token = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    const idToken = getCookie('idToken');

    return !!token && !!refreshToken && !!idToken;
  };

  instance.interceptors.request.use(async (config) => {
    if (!isTokenValid() && window.location.pathname.includes('/dashboard')) {
      try {
        await refreshToken();
      } catch (err) {
        console.error('Failed to refresh session', err);
        clearAuthTokens();
        window.location.href = '/';
        return Promise.reject(err);
      }
    }

    config.headers = { ...config.headers } as AxiosHeaders;
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = `Bearer ${getCookie('accessToken')}`;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response?.data;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401) {
        if (originalRequest._retry && typeof window !== 'undefined') {
          window.location.href = '/';
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await refreshToken();
            originalRequest.headers['Authorization'] = `Bearer ${getCookie('accessToken')}`;
            return axios.request(originalRequest);
          } catch (err) {
            console.error('Failed to refresh session', err);
            window.location.href = '/';
          }
        }
      }

      return Promise.reject(error as AxiosError);
    }
  );

  return instance;
};

export const baseClient = getApiInstance({
  baseURL: `/api`,
});
