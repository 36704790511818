import { DualHRangeBar } from 'dual-range-bar';
import { useLayoutEffect } from 'react';

import { COLORS } from '../../constants';

interface SliderProps {
  onLowerBoundsUpdate: (value: number) => void;
  onUpperBoundsUpdate: (value: number) => void;
  sliderStyles?: React.CSSProperties;
  upperBound: number;
  lowerBound: number;
}

export const Slider = ({
  onLowerBoundsUpdate,
  onUpperBoundsUpdate,
  sliderStyles,
  upperBound = 100,
  lowerBound = 0,
}: SliderProps) => {
  useLayoutEffect(() => {
    // If we initiate it outside the component or outside effect, it will crash with the error of "No my-drbar-container element found"
    const drbar = new DualHRangeBar('my-drbar-container', {
      lowerBound, // Initial value for "lowerBound"
      upperBound, // Initial value for "upperBound"
      minSpan: 1, // Initial value for "minSpan"
      maxSpan: upperBound, // Initial value for "maxSpan"
      lower: 0, // Initial value for "lower"
      upper: upperBound, // Initial value for "upper"
      sliderColor: COLORS.BLUE_BORDER, // Color of the slider
      sliderActiveColor: COLORS.BLUE_BORDER, // Color of the slider when active
      bgColor: COLORS.PRIMARY, // Color of the background
      rangeColor: COLORS.BLUE_PRIMARY, // Color of the range
      rangeActiveColor: COLORS.BLUE_PRIMARY, // Color of the range when active
    });

    drbar.addEventListener('update', (e: Event) => {
      const detail = (e as CustomEvent).detail;
      onLowerBoundsUpdate(detail.lower);
      onUpperBoundsUpdate(detail.upper);
    });

    return () => {
      drbar.removeEventListener('update', () => {});
    };
  }, [onLowerBoundsUpdate, onUpperBoundsUpdate, upperBound, lowerBound]);

  return (
    <div id="my-drbar-container" style={{ marginTop: 12, marginBottom: 4, ...sliderStyles }} />
  );
};
