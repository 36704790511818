import { useMutation, useQueryClient } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '../index';

export const useSkipAllReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ patientId }: { patientId: string }): Promise<void> => {
      await submitSkipAllReviewData(patientId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENTS] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
    },
  });
};

const submitSkipAllReviewData = async (patientId: string): Promise<void> => {
  return baseClient({
    url: `/patients/${patientId}/review/skip-all`,
    method: 'POST',
  });
};
