import { Stack, StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { Marker } from './Marker';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { useTranslation } from 'react-i18next';

interface MarkerContainerProps extends StackProps {
  label: string;
  marker: FileMarker;
}

const MarkerContainer: FC<MarkerContainerProps> = ({ label, marker, ...props }) => {
  return (
    <Stack direction="row" gap="12px" p="3px 8px" {...props}>
      <Stack
        position="relative"
        width="15px"
        alignItems="center"
        sx={{ '& > div': { bgcolor: 'transparent', height: '16px', top: '2px' } }}
      >
        <Marker
          marker={{
            ...marker,
            startSecond: 0,
            endSecond: 10,
          }}
          fileDuration={10}
          startTime={0}
        />
      </Stack>
      <Typography variant="P4_R">{label}</Typography>
    </Stack>
  );
};

export const MachineLearningMarkerLegend: FC<StackProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const marker = { type: 'ML_CREATED' } as FileMarker;

  return <MarkerContainer {...props} marker={marker} label={t('markers.legend.ml')} />;
};

export const DetectedMarkerLegend: FC<StackProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const marker = {
    doctorReview: { reviewType: 'DOCTOR', reviewResult: REVIEW_RESULT_STATUS.DETECTED },
  } as FileMarker;

  return <MarkerContainer {...props} marker={marker} label={t('markers.legend.detected')} />;
};

export const NotDetectedMarkerLegend: FC<StackProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const marker = {
    doctorReview: { reviewType: 'DOCTOR', reviewResult: REVIEW_RESULT_STATUS.NOT_DETECTED },
  } as FileMarker;

  return <MarkerContainer {...props} marker={marker} label={t('markers.legend.notDetected')} />;
};

export const UserFeedbackMarkerLegend: FC<StackProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const marker = { type: 'USER_FEEDBACK' } as FileMarker;

  return <MarkerContainer {...props} marker={marker} label={t('markers.legend.feedback')} />;
};
