export const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const clearAuthTokens = () => {
  const expires = '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = `accessToken${expires}`;
  document.cookie = `refreshToken${expires}`;
  document.cookie = `idToken${expires}`;
};

export const setAuthTokens = (
  accessToken: string,
  expiresIn: number,
  refreshToken: string,
  refreshExpiresIn: number,
  id_token: string
) => {
  clearAuthTokens();

  const accessExpiresDate = new Date(Date.now() + expiresIn * 1000);
  document.cookie = `accessToken=${accessToken}; expires=${accessExpiresDate.toUTCString()}; path=/;`;

  const refreshExpiresDate = new Date(Date.now() + refreshExpiresIn * 1000);
  document.cookie = `refreshToken=${refreshToken}; expires=${refreshExpiresDate.toUTCString()}; path=/;`;

  document.cookie = `idToken=${id_token}; expires=${refreshExpiresDate.toUTCString()}; path=/;`;
};
