import { CustomScrollbar } from 'components/CustomScrollbar';
import { COLORS } from '../../constants';
import { FC, MutableRefObject, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { PatientCard } from 'components/PatientCard';
import { getVideoPageLink } from 'components/calendar/utils';
import { useGetFilesForDay } from 'hooks/useGetFilesForDay';

interface ThumbnailsProps {
  patientId: string;
  selectedFileId: string;
  filesListRef: MutableRefObject<HTMLUListElement | null>;
}

export const Thumbnails: FC<ThumbnailsProps> = ({ patientId, selectedFileId, filesListRef }) => {
  const filesListScrollbarRef = useRef<any>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { filesForDay, isLoading } = useGetFilesForDay();

  const navigate = useNavigate();

  const handleSecondScrollbarPosition = () => {
    filesListScrollbarRef?.current?.handleScrollPositionChange?.();
  };

  return (
    <CustomScrollbar
      selectedItemId={selectedFileId}
      // @ts-ignore
      ref={filesListScrollbarRef}
      thumbColor={COLORS.BLUE_PRIMARY}
      trackColor={COLORS.BLUE_SECOND}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {filesForDay.map((file) => (
            <PatientCard
              key={file.fileId}
              parentRef={filesListRef}
              isFirstLoad={isFirstLoad}
              setIsFirstLoad={setIsFirstLoad}
              handleScrollbarPosition={handleSecondScrollbarPosition}
              fileId={file.fileId}
              isActive={file.fileId === selectedFileId}
              fileUploadedAt={file.fileUploadedAt}
              thumbnailUrl={file.thumbnailUrl}
              onClick={() => navigate(getVideoPageLink(file, patientId))}
            />
          ))}
          <Box sx={{ height: '32px' }}></Box>
        </>
      )}
    </CustomScrollbar>
  );
};
