import { useMutation, useQueryClient } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '../index';

type MarkerBody = {
  startSecond: number;
  endSecond: number;
};

export const useCreateMarker = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMarker,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
    },
  });
};

const createMarker = async ({
  fileId,
  data,
}: {
  fileId: string;
  data: MarkerBody;
}): Promise<void> => {
  return baseClient({
    url: `/files/${fileId}/markers`,
    method: 'POST',
    data,
  });
};
