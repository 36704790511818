import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';

export type ConfigRO = {
  keycloakUrl: string;
};

export const useGetConfig = () =>
  useQuery({
    queryKey: [QUERY_KEYS.CONFIG],
    queryFn: () => getCode(),
  });

const getCode = async (): Promise<ConfigRO> => {
  return baseClient({
    url: `/config`,
    method: 'GET',
  });
};
