import { alpha, createTheme, darken, PaletteOptions } from '@mui/material';
import { UnitedKingdomFlagIcon } from './assets/icons/UnitedKingdomFlagIcon';
import { GermanFlagIcon } from './assets/icons/GermanFlagIcon';

export const LOCALSTORAGE_FILTER_ROUTE_KEY = 'filterRoute';
export const MENU_ID = 'main-menu';
export const DEFAULT_LANGUAGE = 'en';

export const supportedLanguages = ['en', 'de'];

export const LOCALES = {
  de: { title: 'Deutsch', Icon: GermanFlagIcon },
  en: { title: 'English', Icon: UnitedKingdomFlagIcon },
};

type CustomTypographyNames =
  | 'P1_SB'
  | 'P1_R'
  | 'P1_L'
  | 'P2_R'
  | 'P2_SB'
  | 'P3_R'
  | 'P3_SB'
  | 'P3_B'
  | 'P4_R'
  | 'P5_R'
  | 'B1'
  | 'B2'
  | 'label';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<CustomTypographyNames, true> {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    additional: true;
  }
}

interface AdditionalPaletteOptions {
  additional: {
    main: string;
  };
}

interface AdditionalThemeOptions {
  palette: PaletteOptions & AdditionalPaletteOptions;
}

export const SHADOWS = {
  THIRD:
    '0px 1px 2px 0px rgba(2, 42, 80, 0.34), 0px 0px 4px 0px rgba(2, 42, 80, 0.30), 0px 0px 6px 0px rgba(2, 42, 80, 0.18), 0px 0px 7px 0px rgba(2, 42, 80, 0.05), 0px 0px 8px 0px rgba(2, 42, 80, 0.01);',
};

export const COLORS = {
  PRIMARY: '#bbd0e2',
  SECONDARY: '#034986',
  WHITE: '#FFF',
  TABLE_ROW: '#f1f6f9',
  BLACK_PRIMARY: '#212121',
  BLACK_SECOND: '#404040',
  GREEN_PRIMARY: '#A3BCC2',
  GREEN_THIRD: '#0E353F',
  GREEN_SECOND: '#175868',
  GREEN_TERTIARY: '#2A6D54',
  GREY_THIRD: '#EDEDED',
  RED_PRIMARY: '#E0C4B8',
  RED_SECOND: '#762500',
  RED_TERTIARY: '#8D492A',
  SUCCESS: '#B8E0D1',
  SIDEBAR: '#020e1e',
  BLUE_PRIMARY: '#749EC4',
  BLUE_SECOND: '#EDF3F7',
  BLUE_THIRD: '#062446',
  BLUE_FORTH: '#29A0BD',
  BLUE_BORDER: '#0E6296',
  VIOLET_PRIMARY: '#D6B8E0',
  YELLOW_PRIMARY: '#E8CD64',
  GRAY_PRIMARY: '#6E6E6E',
};

export const BACKGROUND = {
  LOGIN: 'linear-gradient(158deg, #FEFEFF 0%, #E4E9F5 100%);',
};

export const mainTheme = createTheme({
  palette: {
    additional: {
      main: COLORS.YELLOW_PRIMARY,
    },
  },
  typography: {
    fontFamily: '"Baloo 2", sans-serif',
    label: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '20px',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '1rem',
    },
    h1: { fontSize: '24px', lineHeight: '36px', fontWeight: 600 },
    h2: { fontSize: '20px', lineHeight: '32px', fontWeight: 500 },
    h3: { fontSize: '20px', lineHeight: '32px', fontWeight: 500 },
    h4: { fontSize: '18px', lineHeight: '27px', fontWeight: 500 },
    h5: { fontSize: '18px', lineHeight: '27px', fontWeight: 400 },

    P1_SB: { fontSize: '16px', lineHeight: '24px', fontWeight: 600 },
    P1_R: { fontSize: '16px', lineHeight: '24px', fontWeight: 400 },
    P1_L: { fontSize: '16px', lineHeight: '20px', fontWeight: 400 },
    P2_R: { fontSize: '16px', lineHeight: '24px', fontWeight: 400 },
    P2_SB: { fontSize: '16px', lineHeight: '24px', fontWeight: 600 },
    P3_R: { fontSize: '16px', lineHeight: '24px', fontWeight: 400 },
    P3_SB: { fontSize: '14px', lineHeight: '20px', fontWeight: 600 },
    P3_B: { fontSize: '12px', lineHeight: '18px', fontWeight: 700 },
    P4_R: { fontSize: '14px', lineHeight: '20px', fontWeight: 400 },
    P5_R: { fontSize: '12px', lineHeight: '18px', fontWeight: 400 },
    B1: { fontSize: '20px', lineHeight: '32px', fontWeight: 400 },
    B2: { fontSize: '16px', lineHeight: '25px', fontWeight: 400 },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontSize: '1rem',
        },
      },
      variants: [
        {
          props: { severity: 'error' },
          style: {
            background: COLORS.BLACK_PRIMARY,
            color: COLORS.WHITE,
            '.MuiSvgIcon-root': {
              color: COLORS.WHITE,
            },
          },
        },
      ],
    },
    MuiSlider: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            height: '4px',
            color: COLORS.BLUE_BORDER,
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            height: '5px',
            color: COLORS.WHITE,
            '.MuiSlider-track': {
              opacity: 1,
              color: COLORS.BLUE_THIRD,
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'label' },
          style: {
            marginBottom: '5px',
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: '14px',
          width: '26px',
          padding: 0,
          margin: '12px',
          '& .MuiSwitch-switchBase': {
            padding: '2px',
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              '& + .MuiSwitch-track': {
                opacity: 1,
                background: 'transparent',
              },
            },
          },
          '& .MuiSwitch-thumb': {
            width: '10px',
            height: '10px',
            boxShadow: 'none',
            background: COLORS.BLACK_SECOND,
          },
          '& .MuiSwitch-track': {
            background: 'transparent',
            opacity: 1,
            border: `1px solid ${COLORS.BLACK_SECOND}`,
          },
          '.Mui-checked': {
            '& + .MuiSwitch-track': {
              borderColor: COLORS.BLUE_FORTH,
              background: `${alpha(COLORS.BLUE_FORTH, 0.2)} !important`,
            },
            '.MuiSwitch-thumb': {
              background: COLORS.BLUE_FORTH,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: COLORS.BLACK_SECOND,
          },
          '&.MuiRadio-colorError': {
            color: 'red',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: COLORS.SECONDARY,
            color: COLORS.WHITE,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: COLORS.WHITE,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: '8px',
          borderRadius: '8px',
          height: '48px',
          fontSize: '0.875rem',
          color: COLORS.BLACK_SECOND,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.BLACK_SECOND,
            borderWidth: '2px',
          },
          '&.MuiInputBase-colorSecondary .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.WHITE,
          },
          '&.MuiInputBase-colorSecondary .MuiOutlinedInput-input': {
            color: COLORS.WHITE,
          },
          '&.Mui-error .MuiOutlinedInput-input': {
            color: COLORS.RED_SECOND,
            zIndex: 1,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            backgroundColor: COLORS.RED_PRIMARY,
            borderColor: `${COLORS.RED_SECOND}`,
            borderWidth: '2px',
          },
          '&.Mui-error': {
            '.MuiIconButton-root': {
              zIndex: 1,
              'svg path': {
                fill: COLORS.RED_SECOND,
              },
            },
          },
        },
        sizeSmall: {
          height: '48px',
          fontSize: '1rem',
          borderRadius: '8px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: COLORS.PRIMARY,
          height: '62px',
          borderTopLeftRadius: '10px',
          th: {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.SECONDARY,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: '#fff',
          backgroundColor: COLORS.SECONDARY,
          '&:hover': {
            backgroundColor: darken(COLORS.SECONDARY, 0.125),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: COLORS.BLACK_PRIMARY,
          padding: '2px 12px',
          lineHeight: '36px',
          fontSize: '16px',
        },
        arrow: {
          color: COLORS.BLACK_PRIMARY,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          borderRadius: '200px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          fontSize: '1rem',
          height: '56px',
          borderRadius: '200px',
          padding: '12px 36px',
        },
        sizeMedium: {
          fontSize: '1rem',
          padding: '10px 50px',
        },
        sizeSmall: {
          fontSize: '1rem',
          padding: '12px 24px',
          width: 'auto',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: COLORS.GREY_THIRD,
            color: '#2121214d',
          },
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
          '&.Mui-disabled': {
            borderWidth: '2px',
          },
        },
        outlinedPrimary: {
          borderWidth: '2px',
          color: COLORS.WHITE,
          borderColor: COLORS.WHITE,
          '&:hover': {
            borderColor: COLORS.WHITE,
            borderWidth: '2px',
          },
        },
        outlinedSecondary: {
          borderWidth: '2px',
          color: COLORS.GREEN_SECOND,
          borderColor: COLORS.GREEN_SECOND,
          '&:hover': {
            borderColor: COLORS.GREEN_SECOND,
            background: '#208AB04D',
          },
        },
        containedPrimary: {
          backgroundColor: COLORS.WHITE,
          color: COLORS.BLUE_THIRD,
          '&:hover': {
            backgroundColor: COLORS.PRIMARY,
          },
        },
        containedSecondary: {
          backgroundColor: COLORS.GREEN_SECOND,
          '&:hover': {
            backgroundColor: '#0E353F',
          },
        },
      },
      variants: [
        {
          props: { color: 'additional', variant: 'outlined' },
          style: {
            borderColor: COLORS.YELLOW_PRIMARY,
            '&:hover': {
              backgroundColor: COLORS.BLACK_PRIMARY,
              borderColor: COLORS.BLACK_PRIMARY,
              color: COLORS.WHITE,
            },
          },
        },
      ],
    },
  },
} as AdditionalThemeOptions);

export enum DEFAULT_URL {
  DASHBOARD = '/dashboard?page=0&order=desc&sort=date&reviewStatus=AWAITING_REVIEW',
}
