import ReactPlayer from 'react-player/lazy';
import { OnProgressProps } from 'react-player/base';
import { useVideoPlayerContext, VideoStateProps } from '../../../contexts/VideoPlayerContext';
import { getCookie } from '../../../api/queries/auth/utils';

export const Video = () => {
  const {
    setIsPlayerReady,
    videoState,
    setVideoState,
    fileUrl,
    videoPlayerRef,
    thumbnailUrl,
    selectedFileId,
  } = useVideoPlayerContext();

  const { playing, muted, seeking } = videoState;

  const bufferStartHandler = () => {
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    setVideoState({ ...videoState, buffer: false });
  };

  const progressHandler = (state: OnProgressProps) => {
    if (!seeking) {
      setVideoState((prevVideoState: VideoStateProps) => ({
        ...prevVideoState,
        ...state,
      }));
    }
  };

  const handleOnReady = () => {
    setIsPlayerReady(true);
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      buffer: false,
    }));
  };
  return (
    <ReactPlayer
      key={`video-${fileUrl}`}
      ref={videoPlayerRef}
      url={fileUrl || ''}
      playing={playing}
      muted={muted}
      config={{
        file: {
          attributes: { poster: thumbnailUrl },
        },
      }}
      onProgress={progressHandler}
      width="100%"
      height="100%"
      progressInterval={50}
      onReady={handleOnReady}
      onBuffer={bufferStartHandler}
      onBufferEnd={bufferEndHandler}
    />
  );
};
