export const SeekForwardIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="1.03711" width="17.5" height="17.5" rx="8.75" fill="white" />
      <rect
        x="0.25"
        y="1.03711"
        width="17.5"
        height="17.5"
        rx="8.75"
        stroke="#034986"
        strokeWidth="0.5"
      />
      <path
        d="M13.1 7.85877V10.4301C13.1 10.5153 13.0684 10.5971 13.0121 10.6573C12.9559 10.7176 12.8796 10.7515 12.8 10.7515H10.4C10.3406 10.7515 10.2826 10.7327 10.2332 10.6974C10.1838 10.6621 10.1453 10.6119 10.1226 10.5531C10.0999 10.4943 10.0939 10.4297 10.1055 10.3673C10.1171 10.3049 10.1457 10.2476 10.1877 10.2027L11.1699 9.15084C10.7017 8.67552 10.1137 8.35761 9.47873 8.23642C8.84372 8.11522 8.18948 8.19607 7.59689 8.46895C7.0043 8.74183 6.49934 9.19478 6.14444 9.77181C5.78954 10.3488 5.60026 11.0247 5.6 11.7157C5.6 11.8009 5.56839 11.8827 5.51213 11.943C5.45587 12.0032 5.37956 12.0371 5.3 12.0371C5.22044 12.0371 5.14413 12.0032 5.08787 11.943C5.03161 11.8827 5 11.8009 5 11.7157C5.00001 10.8973 5.22432 10.097 5.6451 9.414C6.06588 8.731 6.66461 8.19543 7.36696 7.87374C8.06932 7.55205 8.84439 7.45842 9.59598 7.60446C10.3476 7.7505 11.0426 8.12979 11.5948 8.69524L12.5877 7.63137C12.6297 7.58637 12.6832 7.55572 12.7414 7.5433C12.7996 7.53088 12.86 7.53724 12.9148 7.56159C12.9697 7.58594 13.0166 7.62717 13.0495 7.68007C13.0825 7.73298 13.1 7.79517 13.1 7.85877Z"
        fill="#034986"
      />
    </svg>
  );
};
