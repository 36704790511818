import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { keyCloakClient } from '../client/keycloakClient';
import { clearAuthTokens, getCookie } from './utils';

export const useLogout = () =>
  useQuery({
    queryKey: [QUERY_KEYS.LOGOUT],
    queryFn: logOut,
    enabled: false,
  });

export const logOut = async () => {
  await (
    await keyCloakClient
  )({
    url: `/realms/motus/protocol/openid-connect/logout?id_token_hint=${getCookie('idToken')}`,
    method: 'POST',
    data: {
      client_id: 'motus-app',
      refresh_token: getCookie('refreshToken'),
    },
  });
  clearAuthTokens();
  window.location.href = '/';
};
