export const CalendarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/date_range">
      <path
        id="Vector"
        d="M7.65 14.15C7.36667 14.15 7.12917 14.0542 6.9375 13.8625C6.74583 13.6708 6.65 13.4333 6.65 13.15C6.65 12.8667 6.74583 12.6292 6.9375 12.4375C7.12917 12.2458 7.36667 12.15 7.65 12.15C7.93333 12.15 8.17083 12.2458 8.3625 12.4375C8.55417 12.6292 8.65 12.8667 8.65 13.15C8.65 13.4333 8.55417 13.6708 8.3625 13.8625C8.17083 14.0542 7.93333 14.15 7.65 14.15ZM12.075 14.15C11.7917 14.15 11.5542 14.0542 11.3625 13.8625C11.1708 13.6708 11.075 13.4333 11.075 13.15C11.075 12.8667 11.1708 12.6292 11.3625 12.4375C11.5542 12.2458 11.7917 12.15 12.075 12.15C12.3583 12.15 12.5958 12.2458 12.7875 12.4375C12.9792 12.6292 13.075 12.8667 13.075 13.15C13.075 13.4333 12.9792 13.6708 12.7875 13.8625C12.5958 14.0542 12.3583 14.15 12.075 14.15ZM16.325 14.15C16.0417 14.15 15.8042 14.0542 15.6125 13.8625C15.4208 13.6708 15.325 13.4333 15.325 13.15C15.325 12.8667 15.4208 12.6292 15.6125 12.4375C15.8042 12.2458 16.0417 12.15 16.325 12.15C16.6083 12.15 16.8458 12.2458 17.0375 12.4375C17.2292 12.6292 17.325 12.8667 17.325 13.15C17.325 13.4333 17.2292 13.6708 17.0375 13.8625C16.8458 14.0542 16.6083 14.15 16.325 14.15ZM4.5 22C4.1 22 3.75 21.85 3.45 21.55C3.15 21.25 3 20.9 3 20.5V5C3 4.6 3.15 4.25 3.45 3.95C3.75 3.65 4.1 3.5 4.5 3.5H6.125V2.8C6.125 2.57333 6.20167 2.38333 6.355 2.23C6.50833 2.07667 6.69833 2 6.925 2C7.15875 2 7.35469 2.07667 7.51283 2.23C7.67094 2.38333 7.75 2.57333 7.75 2.8V3.5H16.25V2.8C16.25 2.57333 16.3267 2.38333 16.48 2.23C16.6333 2.07667 16.8233 2 17.05 2C17.2838 2 17.4797 2.07667 17.6378 2.23C17.7959 2.38333 17.875 2.57333 17.875 2.8V3.5H19.5C19.9 3.5 20.25 3.65 20.55 3.95C20.85 4.25 21 4.6 21 5V20.5C21 20.9 20.85 21.25 20.55 21.55C20.25 21.85 19.9 22 19.5 22H4.5ZM4.5 20.5H19.5V9.75H4.5V20.5ZM4.5 8.25H19.5V5H4.5V8.25Z"
        fill="#212121"
      />
    </g>
  </svg>
);
